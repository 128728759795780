import React, { useContext } from 'react';

import { useSelector } from 'react-redux';

import { Conditional } from '@/components/common/Conditional';
import { AttacherModal } from '@/components/common/Modals/AttacherModal';
import { UserContext } from '@/context/UserContext';
import { useDocTypeObj } from '@/hooks/useDocType';
import { useLazySaveOctaneQuery } from '@/store/document.api';
import { selectDocument } from '@/store/document.slice';
import { selectEditor } from '@/store/editor.slice';
import { useAppDispatch } from '@/store/store';
import {
  consumed,
  modalOpenState,
  selectFromXOne,
  selectIsConsumed,
  selectQueryParams,
  selectTimedout,
} from '@/store/ui.slice';
import { ButtonWrap, type IRecord } from '../common/RightButtonWrap';
import { useFileLoaded } from '../common/isFileLoaded';
import { ChasingButtons } from './ChasingButtons';

export const RightHeaderButtons = ({ values }: { values: IRecord }) => {
  const dispatch = useAppDispatch();

  const editor = useSelector(selectEditor);
  const file = useSelector(selectDocument);
  const fromXone = useSelector(selectFromXOne);

  const timedout = useSelector(selectTimedout);
  const { isbomanager } = useSelector(selectQueryParams);
  const saveConsumed = useSelector(selectIsConsumed('Save'));
  const sendConsumed = useSelector(selectIsConsumed('SendOctane'));

  const { sgConnect } = useContext(UserContext).globalContext;

  const {
    Confirmation /* ReadOnlyDocument */,
    /* PreConfirmation */ /* Chasing */ /* Affirmation */ /* Prematching */ /* FlowAdvice */ /* FlowCorrespondence */ /* IssuingDocumentation */ Invoice,
    MRB,
  } = useDocTypeObj(file.documentType);

  const loaded = useFileLoaded(file);

  const [triggerSaveOctane, responseSendOctane] = useLazySaveOctaneQuery();

  const saveOctane = async () => {
    const { assetClass, tradeReference, documentId, documentType } = file;

    if (assetClass === '' || tradeReference === '' || !documentId || documentType === '') {
      return;
    }

    const currentText = (await editor?.saveToString())?.draftXML;
    if (!currentText) {
      return;
    }

    const res = await triggerSaveOctane({
      assetClass,
      tradeReference,
      documentId,
      documentType,
      action: Confirmation ? 'SaveToValidate' : 'Save',
      workingDocument: currentText,
      userBdrInitials: sgConnect?.getIdTokenClaims()?.sub.split('@')[0],
    });
    if (res.isSuccess) {
      dispatch(consumed('Save'));
    }
  };

  return (
    <Conditional condition={!fromXone}>
      <AttacherModal />
      <ButtonWrap
        condition={Confirmation || Invoice}
        disabled={!loaded || timedout || saveConsumed || sendConsumed}
        loading={responseSendOctane.isLoading || responseSendOctane.isFetching}
        icon="save"
        action={saveOctane}
        label={
          values.labels[1]
            ? `header.button.octane.${Confirmation ? 'db_' : ''}sav${
                responseSendOctane.isLoading || responseSendOctane.isFetching ? 'ing' : 'e'
              }`
            : undefined
        }
        mr={values.mr[0]}
      />

      <ButtonWrap
        condition={((Confirmation || Invoice) && (isbomanager || Invoice)) || MRB}
        disabled={!loaded || timedout || sendConsumed}
        icon="send"
        action={() => dispatch(modalOpenState({ modalId: 'SaveOctane', openState: true }))}
        label={MRB ? 'header.button.octane.mail_send' : 'header.button.octane.save_send'}
        btnType="btn-info"
      />

      <ChasingButtons values={values} />
    </Conditional>
  );
};
