import type React from 'react';
import { useState } from 'react';

export interface IPropsDropdown<T extends string> {
  onChange: (value: T) => void;
  values: T[] | [T, React.ReactNode, boolean?][];
  defaultValue?: T;
  dropName?: string | React.ReactNode;
  useDropeNameAsTitle?: boolean;
  activable?: boolean;
  hideArrow?: boolean;
  containerClassName?: string;
  disabled?: boolean;
  btnClass?: string;
}

const chooseActiveOrDropName = (active: string, dropName?: string | React.ReactNode) =>
  active.length ? active : (dropName ?? 'dropdown');

export const Dropdown = <T extends string>({
  onChange,
  values,
  defaultValue,
  dropName,
  useDropeNameAsTitle,
  activable,
  hideArrow,
  containerClassName,
  disabled,
  btnClass,
}: IPropsDropdown<T>) => {
  const [open, setOpen] = useState(false);
  const [active, setActive] = useState(defaultValue ?? '');

  const labelClick = (e: T) => {
    setActive(e);
    setOpen(false);

    onChange(e);
  };

  return (
    <div className="dropdown">
      <button
        disabled={disabled}
        type="button"
        className={`btn btn-lg btn-link${hideArrow ? '' : ' dropdown-toggle'}${
          disabled ? ' disabled' : ''
        }${btnClass ? ` ${btnClass}` : ''}`}
        id="dropdownMenuButton"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="true"
        onClick={() => setOpen(!open)}
      >
        {useDropeNameAsTitle === false ? chooseActiveOrDropName(active, dropName) : (dropName ?? 'dropdown')}
      </button>
      <div
        className={`dropdown-menu scrollable-menu${open ? ' show' : ''} ${containerClassName ?? ''}`}
        id="dropdownContainer"
        aria-labelledby="dropdownMenuButton"
        style={{ maxHeight: '200px', overflowY: 'auto' }}
      >
        {values.map((val) => {
          if (typeof val === 'string') {
            return (
              <DropdownItem
                value={val}
                label={val}
                className={`dropdown-item ${
                  val === active && activable === true ? 'active' : ''
                }${disabled ? ' disabled' : ''}`}
                onClick={() => labelClick(val)}
                isDisabled={!(val ?? false)}
              />
            );
          }
          return (
            <DropdownItem
              value={val[0]}
              label={val[1]}
              className={`dropdown-item ${
                val[0] === active && activable === true ? 'active' : ''
              }${disabled ? ' disabled' : ''}`}
              onClick={() => labelClick(val[0])}
              isDisabled={!(val[2] ?? false)}
              key={val[0]}
            />
          );
        })}
      </div>
    </div>
  );
};

type DropdownItemProps = {
  value: string;
  label: string | React.ReactNode;
  className: string;
  onClick: () => void;
  isDisabled: boolean;
};

function DropdownItem({ value, label, className, onClick, isDisabled }: DropdownItemProps) {
  return (
    <button type="button" onClick={onClick} className={className} id={value} key={value} disabled={isDisabled}>
      {label}
    </button>
  );
}
