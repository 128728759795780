import { DocumentContext } from '@/context/DocumentContext';
import { UseTrackLoadingPage } from '@/hooks/useTrackLoadingPage';
import { selectRights } from '@/store/ui.slice';
import { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { PreviewXoneEditor } from '@/components/routes/Editor/Xone/PreviewXone/PreviewXoneEditor/PreviewXoneEditor';
import { PreviewXoneReader } from '@/components/routes/Editor/Xone/PreviewXone/PreviewXoneReader/PreviewXoneReader';
import { useLocation } from 'react-router';
import { createToast } from '@/components/common/ToasterProvider';
import { FormattedMessage } from 'react-intl';
import { useFetchDocument } from '@/components/routes/Editor/common/useFetchDocument';

export function PreviewPage() {
  const { setDocument } = useContext(DocumentContext);
  const { pathname } = useLocation();

  const { canUseSmartDX } = useSelector(selectRights);

  const [document, isFileLoaded] = useFetchDocument(true);

  useEffect(() => {
    if(isFileLoaded) {
      setDocument(document);
    }
  }, [isFileLoaded, document, setDocument]);

  UseTrackLoadingPage();

  // TODO ABO, review what to do if document is null
  if(pathname !== '/preview' && document === null) {
    if(isFileLoaded) {
      createToast({
        text: <FormattedMessage id="smartdx.saveToString.no_doc" />,
        level: 'danger',
        icon: 'error_outline',
        type: 'alert',
      });
    }

    return null;
  }

  return canUseSmartDX ? <PreviewXoneEditor /> : <PreviewXoneReader />;
}
