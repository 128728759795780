import { Conditional } from '@/components/common/Conditional';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { useLazyPutDocumentQuery } from '@/store/document.api';
import { selectDocument } from '@/store/document.slice';
import { selectEditor } from '@/store/editor.slice';
import { consumed, selectTimedout } from '@/store/ui.slice';
import { useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

export function SaveButton({ draftXmlDocument }: { draftXmlDocument: string | null }) {
  const dispatch = useDispatch();

  const timedout = useSelector(selectTimedout);
  const editor = useSelector(selectEditor);

  const isUnderXlScreenSize = useMediaQuery([['(min-width: 1200px)', false]], true);
  const extraClasses = useMemo(() => (isUnderXlScreenSize ? ' mr-1' : ' btn-text-icon mr-2'), [isUnderXlScreenSize]);

  const fileInfo = useSelector(selectDocument);
  const [triggerSave, responseSave] = useLazyPutDocumentQuery();

  const saveFile = useCallback(async () => {
    const { assetClass, tradeReference, documentId, documentType } = fileInfo;
    const hasFileInfos = assetClass !== '' && tradeReference !== '' && documentId && documentType !== '';

    const editorDocument = await editor?.saveToString();
    const draftXmlDocumentToSave = editorDocument?.draftXML ?? draftXmlDocument;

    if (!hasFileInfos || !draftXmlDocumentToSave) {
      return undefined;
    }

    return triggerSave({
      assetClass,
      tradeReference,
      documentId,
      documentType,
      workingDocument: draftXmlDocumentToSave,
    });
  }, [draftXmlDocument, editor, fileInfo, triggerSave]);

  async function handleClick() {
    const result = await saveFile();

    if (result?.isSuccess) {
      dispatch(consumed('Save'));
    }
  }

  return (
    <button
      className={`btn sgbs-btn-default btn-lg${extraClasses}`}
      type="button"
      onClick={handleClick}
      disabled={timedout}
    >
      <Conditional condition={!isUnderXlScreenSize}>
        {responseSave.isLoading || responseSave.isFetching ? (
          <FormattedMessage id={'header.button.saving'} />
        ) : (
          <FormattedMessage id={'header.button.save'} />
        )}
      </Conditional>
      <i className="icon">save</i>
    </button>
  );
}
