import { AttachmentsModal } from '@/components/common/Modals/AttachmentsModal';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { modalOpenState } from '@/store/ui.slice';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { DropdownItem } from 'reactstrap';

export function AttachmentButton({
  isDropdownItem = false,
  disabled,
}: {
  isDropdownItem?: boolean;
  disabled: boolean;
}) {
  const dispatch = useDispatch();
  const isUnderLgSize = useMediaQuery([['(min-width: 992px)', false]], true);

  const openAttachementModal = () => dispatch(modalOpenState({ modalId: 'Attachment', openState: true }));

  return (
    <>
      <AttachmentsModal />

      {!isDropdownItem ? (
        <button
          type="button"
          className={`btn btn-flat-secondary btn-lg ${isUnderLgSize ? '' : 'btn-icon-text'}`}
          onClick={openAttachementModal}
          disabled={disabled}
        >
          <i className="icon">attach_file</i>
          <span className="d-none d-xl-inline">
            <FormattedMessage id="header.button.attachments" />
          </span>
        </button>
      ) : (
        <DropdownItem
          className="btn btn-flat-secondary btn-lg btn-icon-text"
          onClick={openAttachementModal}
          disabled={disabled}
        >
          <i className="icon">attach_file</i>
          <span>
            <FormattedMessage id="header.button.attachments" />
          </span>
        </DropdownItem>
      )}
    </>
  );
}
