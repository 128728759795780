// https://usehooks.com/useMedia/
import { useEffect, useState } from 'react';

export type QueryMatch<T> = [string, T];

interface MediaQueryMatcher<T> {
  mediaQuery: MediaQueryList;
  value: T;
}

export function useMediaQuery<T>(queries: QueryMatch<T>[], defaultValue: T) {
  const matchers: MediaQueryMatcher<T>[] = queries.map((query) => ({
    mediaQuery: window.matchMedia(query[0]),
    value: query[1],
  }));

  const getValue = () => {
    const match = matchers.find((matcher) => matcher.mediaQuery.matches);
    return match !== undefined ? match.value : defaultValue;
  };

  const [value, setValue] = useState(getValue);

  useEffect(
    () => {
      const handler = () => setValue(getValue);

      for(const matcher of matchers) {
        matcher.mediaQuery.addEventListener('change', handler);
      }
      
      return () => {
        for(const matcher of matchers) {
          matcher.mediaQuery.removeEventListener('change', handler)
        }
      }
    },
    // Empty array ensures effect is only run on mount and unmount
    [],
  );

  return value;
}
