import { Conditional } from '@/components/common/Conditional';
import { ChasingButtonsWrapper } from '@/components/routes/Editor/common/ChasingButtonsWrapper/ChasingButtonsWrapper';
import { ExportButton } from '@/components/routes/Editor/common/ExportButton/ExportButton';
import { FileInfoDisplay } from '@/components/routes/Editor/common/FileInfoDisplay';
import { ReaderLoadUrlButton } from '@/components/routes/Editor/common/ReaderLoadUrlButton';
import { SaveButtonsWrapper } from '@/components/routes/Editor/common/SaveButtonsWrapper/SaveButtonsWrapper';
import { DocumentContext } from '@/context/DocumentContext';
import { selectDocument } from '@/store/document.slice';
import { selectRights } from '@/store/ui.slice';
import { useContext } from 'react';
import { useSelector } from 'react-redux';

type ReviewXoneEditorHeaderProps = {
  documentBlob: Blob | null;
};

export function ReviewXoneReaderHeader({ documentBlob }: ReviewXoneEditorHeaderProps) {
  const { document: draftXmlDocument } = useContext(DocumentContext);

  const rights = useSelector(selectRights);
  const { documentType, isReadOnly } = useSelector(selectDocument);

  const isChasingDocumentType = documentType?.trim().toLowerCase() === 'chasing';

  const { canWrite } = rights;

  return (
    <div className="col d-flex align-items-center">
      <ReaderLoadUrlButton />
      <FileInfoDisplay />

      <div className="ml-auto d-flex">
        <ExportButton document={documentBlob} />

        <Conditional condition={canWrite && !isReadOnly}>
          {!isChasingDocumentType ? (
            <SaveButtonsWrapper draftXmlDocument={draftXmlDocument} />
          ) : (
            <ChasingButtonsWrapper draftXmlDocument={draftXmlDocument} />
          )}
        </Conditional>
      </div>
    </div>
  );
}
