import { useMatomo } from '@datapunt/matomo-tracker-react';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { Conditional } from '@/components/common/Conditional';
import { Dropdown } from '@/components/common/DropDown';

import { CancelChaseModal } from '@/components/common/Modals/CancelChaseModal';
import { ChaseModal } from '@/components/common/Modals/ChaseModal';
import { SaveAndSendModal } from '@/components/common/Modals/SaveAndSendModal';
import { SaveAndStoreModal } from '@/components/common/Modals/SaveAndStoreModal';

import { ToasterContext } from '@/context/ToasterContext';

import { SaveOctaneModal } from '@/components/common/Modals/saveOctaneModal';
import { UserContext } from '@/context/UserContext';
import { selectDocument } from '@/store/document.slice';
import { selectEditorState } from '@/store/editor.slice';
import { selectFromXOne } from '@/store/ui.slice';
import { RightHeaderButtons as RightHeaderOctaneButtons } from '../Octane/RightHeaderButtons';
import { RightHeaderButtons as RightHeaderXoneButtons } from '../Xone/RightHeaderButtons';
import { record } from './RightButtonWrap';
import { type Exportable, exportFileAsync } from './exportFile';

type Enabled = true | false;

export const RightHeader = () => {
  const intl = useIntl();
  const { trackEvent } = useMatomo();
  const { editor, currentContent } = useSelector(selectEditorState);
  const file = useSelector(selectDocument);
  const { globalContext } = React.useContext(UserContext);
  const fromXone = useSelector(selectFromXOne);

  const exportDropValues = (): [Exportable, React.JSX.Element, Enabled][] =>
    [
      [
        'doc',
        <>
          <FormattedMessage id="header.button.export.export_as" key="doc" /> .doc
        </>,
        true,
      ],
      [
        'html',
        <>
          <FormattedMessage id="header.button.export.export_as" key="html" /> .html
        </>,
        true,
      ],
      [
        'pdf',
        <>
          <FormattedMessage id="header.button.export.export_as" key="pdf" /> .pdf
        </>,
        file.documentType !== 'RegReportBAS100' && file.documentType !== 'RegReportBAS110',
      ],
      [
        'txt',
        <>
          <FormattedMessage id="header.button.export.export_as" key="txt" /> .txt
        </>,
        file.documentType === 'RegReportBAS100' || file.documentType === 'RegReportBAS110',
      ],
      [
        'xml',
        <>
          <FormattedMessage id="header.button.export.export_as" key="xml" /> .xml
        </>,
        true,
      ],
    ].filter((ex) => ex[0] !== 'pdf' || fromXone) as [Exportable, React.JSX.Element, Enabled][];

  const chasing = React.useMemo(
    () => (file?.documentType ?? file.documentType)?.trim().toLowerCase() === 'chasing',
    [file.documentType],
  );

  const pushToast = React.useContext(ToasterContext).pushToastMessage;

  const exportFile = React.useCallback(
    async (values: Exportable) =>
      editor
        ? exportFileAsync(values, file, editor, (await editor.saveToString()).draftXML, {
            pushToast,
            intl,
            trackEvent,
            globalContext,
          })
        : null,
    [file, editor, pushToast, intl, trackEvent, globalContext],
  );

  const [draftXmlDocument, setDraftXmlDocument] = useState<string | null>(null);

  useEffect(() => {
    if (currentContent) {
      const getdraftXmlDocument = async () => {
        const result = (await editor?.saveToString())?.draftXML ?? null;
        setDraftXmlDocument(result);
      };

      getdraftXmlDocument();
    }
  }, [currentContent, editor]);

  return (
    <>
      <div className="col">
        <Conditional condition={!chasing && fromXone}>
          <SaveAndStoreModal draftXmlDocument={draftXmlDocument} />
          <SaveAndSendModal draftXmlDocument={draftXmlDocument} />
        </Conditional>
        <Conditional condition={chasing && fromXone}>
          <CancelChaseModal draftXmlDocument={draftXmlDocument} />
          <ChaseModal draftXmlDocument={draftXmlDocument} />
        </Conditional>
        <Conditional condition={!fromXone}>
          <SaveOctaneModal draftXmlDocument={draftXmlDocument} />
        </Conditional>

        {record.map((values) => (
          <div key={values.class} className={`${values.class} justify-content-end`}>
            <Dropdown
              onChange={exportFile}
              disabled={file.tradeReference === undefined}
              values={exportDropValues()}
              hideArrow
              dropName={
                <Conditional fallback={<i className="icon">ios_share</i>} condition={values.labels[0]}>
                  <FormattedMessage id="header.button.export" />
                  <i className="icon">ios_share</i>
                </Conditional>
              }
              btnClass="btn btn-flat-secondary btn-lg btn-text-icon mr-2"
              useDropeNameAsTitle
            />

            <RightHeaderXoneButtons values={values} />
            <RightHeaderOctaneButtons values={values} />
          </div>
        ))}
      </div>
    </>
  );
};
