import { Conditional } from '@/components/common/Conditional';
import { ExportButton } from '@/components/routes/Editor/common/ExportButton/ExportButton';
import { FileInfoDisplay } from '@/components/routes/Editor/common/FileInfoDisplay';
import { DocumentContext } from '@/context/DocumentContext';
import { useDocTypeObj } from '@/hooks/useDocType';
import { selectChasing, selectDocument } from '@/store/document.slice';
import { selectQueryParams } from '@/store/ui.slice';
import { isPlainChasingInfos } from '@/types/fileInfos';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { ReaderLoadUrlButton } from '../../common/ReaderLoadUrlButton';
import { SaveButton } from '../OctaneButtons/SaveButton';
import { SaveChasingButton } from '../OctaneButtons/SaveChasingButton';
import { SaveChasingModalButton } from '../OctaneButtons/SaveChasingModalButton';
import { SendButton } from '../OctaneButtons/SendButton';
import { SendChasingButton } from '../OctaneButtons/SendChasingButton';

type ReviewXoneEditorHeaderProps = {
  documentBlob: Blob | null;
};

export function OctaneReaderHeader({ documentBlob }: ReviewXoneEditorHeaderProps) {
  const { document: draftXmlDocument } = useContext(DocumentContext);

  const { documentType } = useSelector(selectDocument);
  const chasing = useSelector(selectChasing);

  const { isbomanager } = useSelector(selectQueryParams);
  const { Confirmation, Invoice, MRB } = useDocTypeObj(documentType);

  const shouldDisplaySaveButton = Confirmation || Invoice;
  const shouldDisplaySendButton = ((Confirmation || Invoice) && (isbomanager || Invoice)) || MRB;

  return (
    <>
      <div className="bg-lvl1 col d-flex align-items-center">
        <ReaderLoadUrlButton />

        <FileInfoDisplay />

        <div className="ml-auto d-flex">
          {documentBlob !== null && <ExportButton document={documentBlob} />}

          {shouldDisplaySaveButton && <SaveButton draftXmlDocument={draftXmlDocument} Confirmation={Confirmation} />}
          {shouldDisplaySendButton && <SendButton draftXmlDocument={draftXmlDocument} MRB={MRB} />}

          <Conditional condition={isPlainChasingInfos(chasing)}>
            <SaveChasingModalButton />
            <SaveChasingButton draftXmlDocument={draftXmlDocument} />
            <SendChasingButton draftXmlDocument={draftXmlDocument} />
          </Conditional>
        </div>
      </div>
    </>
  );
}
