import { ConcurencyModal } from '@/components/common/Modals/ConcurencyModal';
import { OctaneReaderHeader } from '@/components/routes/Editor/Octane/OctaneReader/OctaneReaderHeader';
import {
  ReviewXoneReaderHeader,
} from '@/components/routes/Editor/Xone/ReviewXone/ReviewXoneReader/ReviewXoneReaderHeader';
import { DocumentContext } from '@/context/DocumentContext';
import { UserContext } from '@/context/UserContext';
import { useFetchDocumentBlob } from '@/hooks/useFetchDocumentBlob';
import { selectOrigin } from '@/store/ui.slice';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Reader } from '@/components/layout/Reader';

export function ReviewReader() {
  const { globalContext } = useContext(UserContext);

  const isOctane = useSelector(selectOrigin) === 'octane';

  const { document } = useContext(DocumentContext);

  const { documentBlob, isLoading } = useFetchDocumentBlob(document, globalContext);

  const isHtmlBlob = documentBlob?.type === 'text/html';

  return (
    <Reader isLoading={isLoading} isHtmlBlob={isHtmlBlob} documentBlob={documentBlob}>
      <ConcurencyModal />

      {isOctane ? (
        <OctaneReaderHeader documentBlob={documentBlob} />
      ) : (
        <ReviewXoneReaderHeader documentBlob={documentBlob} />
      )}
    </Reader>
  );
}
