import { CompareModal } from '@/components/common/Modals/CompareModal';
import { LoadUrlModal } from '@/components/common/Modals/LoadURLModal';
import { selectDocument } from '@/store/document.slice';
import { modalOpenState } from '@/store/ui.slice';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ExportDropdown } from '../../../common/ExportButton/ExportDropdown';
import { getExportDropValues } from '../../../common/exportFile';
import { CompareButton } from './CompareButton';

export function SgDocPreviewEditorHeader() {
  const dispatch = useDispatch();
  const openLoadURLModal = () => dispatch(modalOpenState({ modalId: 'LoadURL', openState: true }));

  const { documentType } = useSelector(selectDocument);

  const exportDropValues = getExportDropValues(documentType);

  return (
    <>
      <div className="col d-flex align-items-center">
        <LoadUrlModal />
        <CompareModal />
        <button type="button" className="btn sgbs-btn-default btn-lg " onClick={openLoadURLModal}>
          <i className="icon">folder_open</i>
        </button>
        <CompareButton />
        <div className="ml-auto">
          <ExportDropdown exportValues={exportDropValues} />
        </div>
      </div>
    </>
  );
}
