import { DocumentContext } from '@/context/DocumentContext';
import { useGetSgDocDocumentQuery } from '@/store/document.api';
import { selectDocument } from '@/store/document.slice';
import { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  SgDocPreviewEditor,
} from '@/components/routes/Editor/SgDocs/SgDocPreview/SgDocPreviewEditor/SgDocPreviewEditor';
import {
  SgDocPreviewReader,
} from '@/components/routes/Editor/SgDocs/SgDocPreview/SgDocPreviewReader/SgDocPreviewReader';
import { selectRights } from '@/store/ui.slice';

export const SgDocPreview = () => {
  const { canUseSmartDX } = useSelector(selectRights);

  const { documentId: sgDocsId } = useSelector(selectDocument);
  const { data: documentData, isSuccess } = useGetSgDocDocumentQuery(
    { documentId: sgDocsId },
    { skip: sgDocsId === undefined },
  );

  const { setDocument } = useContext(DocumentContext);

  useEffect(() => {
    if (isSuccess) {
      setDocument(documentData);
    }
  }, [isSuccess, documentData, setDocument]);

  return <>{canUseSmartDX ? <SgDocPreviewEditor /> : <SgDocPreviewReader />}</>;
};
