import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import type { Exportable } from '../exportFile';
import { ExportDocItem } from './ExportDocItem';
import { ExportHtmlItem } from './ExportHtmlItem';
import { ExportPdfItem } from './ExportPdfItem';
import { ExportTxtItem } from './ExportTxtItem';
import { ExportXmlItem } from './ExportXmlItem';

type ExportDropdownValue = {
  type: Exportable;
  enabled: boolean;
};

export function ExportDropdown({ exportValues }: { exportValues: ExportDropdownValue[] }) {
  const [dropDownOpen, setDropDownOpen] = useState<boolean>(false);
  const toggle = () => setDropDownOpen((prevState) => !prevState);

  return (
    <Dropdown isOpen={dropDownOpen} toggle={toggle}>
      <DropdownToggle color="flat-secondary" className="btn-lg btn-text-icon mr-2 border-0">
        <span className="d-none d-xl-inline">
          <FormattedMessage id="header.button.export" />
        </span>
        <i className="icon">ios_share</i>
      </DropdownToggle>
      <DropdownMenu>
        {exportValues.map(({ type, enabled }) => {
          switch (type) {
            case 'pdf':
              return <ExportPdfItem enabled={enabled} key={type} />;
            case 'doc':
              return <ExportDocItem enabled={enabled} key={type} />;
            case 'txt':
              return <ExportTxtItem enabled={enabled} key={type} />;
            case 'xml':
              return <ExportXmlItem enabled={enabled} key={type} />;
            case 'html':
              return <ExportHtmlItem enabled={enabled} key={type} />;
          }
        })}
      </DropdownMenu>
    </Dropdown>
  );
}
