import { AttachmentsModal } from '@/components/common/Modals/AttachmentsModal';
import { CompareModal } from '@/components/common/Modals/CompareModal';
import { LoadUrlModal } from '@/components/common/Modals/LoadURLModal';
import { ActionsWrapper } from '@/components/routes/Editor/common/ActionsWrapper/ActionsWrapper';
import { ExportDropdown } from '@/components/routes/Editor/common/ExportButton/ExportDropdown';
import { FileInfoDisplay } from '@/components/routes/Editor/common/FileInfoDisplay';
import { getExportDropValues } from '@/components/routes/Editor/common/exportFile';
import { useDocTypeObj } from '@/hooks/useDocType';
import { selectDocument } from '@/store/document.slice';
import { modalOpenState } from '@/store/ui.slice';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

export function PreviewXoneEditorHeader() {
  const dispatch = useDispatch();

  const { documentType, fileName } = useSelector(selectDocument);

  const {
    Confirmation,
    PreConfirmation,
    ReadOnlyDocument,
    Chasing,
    Affirmation,
    PreMatching,
    FlowAdvice,
    TermSheet,
    /* FlowCorrespondence */ IssuingDocumentation,
  } = useDocTypeObj(documentType);

  const openLoadURLModal = () => dispatch(modalOpenState({ modalId: 'LoadURL', openState: true }));

  const exportDropValues = getExportDropValues(documentType);

  const shouldDisplayCompare =
    Confirmation || ReadOnlyDocument || FlowAdvice || IssuingDocumentation || PreMatching || TermSheet;

  const shouldDisplayAttachment =
    fileName !== undefined && (PreConfirmation || Chasing || Affirmation || PreMatching || TermSheet);
  const actionList = ['compare', 'attachment'];
  const actionsToDisplay = actionList.filter(
    (action) => (action === 'compare' && shouldDisplayCompare) || (action === 'attachment' && shouldDisplayAttachment),
  );

  return (
    <>
      <div className="col d-flex align-items-center">
        <LoadUrlModal />
        <AttachmentsModal />
        <CompareModal />
        <button type="button" className="btn sgbs-btn-default btn-lg " onClick={openLoadURLModal}>
          <i className="icon">folder_open</i>
        </button>

        <FileInfoDisplay />

        {actionsToDisplay.length > 0 && <ActionsWrapper actions={actionsToDisplay} />}

        <div className="ml-auto">
          <ExportDropdown exportValues={exportDropValues} />
        </div>
      </div>
    </>
  );
}
