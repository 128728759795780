import { ToasterContext } from '@/context/ToasterContext';
import { UserContext } from '@/context/UserContext';
import { logger } from '@/helpers/logger';
import { DocumentService } from '@/services/DocumentService';
import { selectDocument } from '@/store/document.slice';
import { selectEditor } from '@/store/editor.slice';
import type { SmartEditor } from '@/types/SmartCommsApi';
import { useCallback, useContext } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { download } from '../exportFile';
import { ExportItem } from './ExportItem';

type ExportDocItemProps = {
  enabled: boolean;
};

export function ExportDocItem({ enabled }: ExportDocItemProps) {
  const { globalContext } = useContext(UserContext);
  const file = useSelector(selectDocument);
  const editor = useSelector(selectEditor);
  const pushToast = useContext(ToasterContext).pushToastMessage;
  const intl = useIntl();

  const exportDoc = useCallback(
    async (editor: SmartEditor | undefined) => {
      const currentContent = (await editor?.saveToString())?.draftXML;

      if (!editor) {
        pushToast({
          text: intl.formatMessage({ id: 'notification.export.failure' }),
          level: 'danger',
          icon: 'error_outline',
          type: 'alert',
        });
        return;
      }

      try {
        if (!currentContent) {
          logger.logWarning(
            'Failed to export document, draftXML is empty {tradeRef} {docId}',
            file?.tradeReference,
            file?.documentId,
          );
          return;
        }

        const fileBlobI = await DocumentService.getDocument(
          new AbortController().signal,
          globalContext,
          file?.tradeReference,
          file?.documentId ?? '',
          file?.documentType,
        );
        if (fileBlobI.originalAttachment) {
          download(
            new Blob([Buffer.from(fileBlobI.originalAttachment, 'base64')], {
              type: 'application/msword',
            }),
            `${file?.documentType}_${file?.tradeReference ?? file?.documentId}.doc`,
          );
        }

        pushToast({
          text: intl.formatMessage({ id: 'notification.export.success' }),
          level: 'info',
          icon: 'info_outline',
        });
      } catch (e) {
        pushToast({
          text: intl.formatMessage({ id: 'notification.export.failure' }),
          level: 'danger',
          icon: 'error_outline',
          type: 'alert',
        });
      }
    },
    [file?.documentId, file?.documentType, file?.tradeReference, globalContext, intl, pushToast],
  );

  return <ExportItem type="doc" onClick={() => exportDoc(editor)} isDisabled={!enabled} />;
}
