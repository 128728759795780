import { useEffect, useState } from 'react';
import { useLazyGetChasingQuery } from '@/store/document.api';
import { useDispatch, useSelector } from 'react-redux';
import { selectFromXOne, selectQueryParams } from '@/store/ui.slice';
import { base64ToBytes } from '@/components/routes/Editor/utils';
import { type ChasingInfo, isPlainChasingInfos } from '@/types/fileInfos';
import { selectDocument, setFile } from '@/store/document.slice';

export function useFetchDocumentChasing(chasing: ChasingInfo): [ string | null, boolean] {
 const dispatch = useDispatch();

  const [document, setDocument] = useState<string | null>(null);
  const [isFileLoaded, setIsFileLoaded] = useState<boolean>(false);

  const isXone = useSelector(selectFromXOne);

  const fileInfos = useSelector(selectDocument);
  const { isoriginal } = useSelector(selectQueryParams);

  const [ trigger, { data: documentInfos, isFetching, isLoading, isSuccess } ] = useLazyGetChasingQuery();

  useEffect(() => {
    const isDocumentQueryWorking = !isFileLoaded && !isFetching && !isLoading;

    if(isDocumentQueryWorking && isPlainChasingInfos(chasing)) {
      trigger(chasing);

      if(isSuccess && documentInfos) {
        const {originalContent, workingContent, value, isReadOnly} = documentInfos;

        const selectedContent = isoriginal
          ? (originalContent ?? workingContent ?? null)
          : (workingContent ?? originalContent ?? null);

        const documentEncoded = getDocumentContent(fileInfos.documentId, selectedContent, value, isXone);
        const documentDecoded = documentEncoded !== null ? new TextDecoder().decode(base64ToBytes(documentEncoded)) : null;

        setDocument(documentDecoded);
        setIsFileLoaded(true);

        const { documentId } = fileInfos;
        if(documentId !== 'null' && selectedContent) {
          dispatch(
            setFile({
              ...fileInfos,
              isReadOnly,
            }),
          );
        }
      }
    }

    }, [ documentInfos, fileInfos, isFetching, isFileLoaded, isLoading, isSuccess, isoriginal, trigger, dispatch, isXone, chasing ])

  return [document, isFileLoaded];
}

function getDocumentContent(documentId: string | undefined, selectedContent: string | null, value: string | undefined, isXone: boolean) {
  if((documentId !== 'null' || !isXone) && selectedContent) {
    return selectedContent;
  }

  if(documentId === 'null' && value) {
    return value;
  }

  return null;
}