import { CompareModal } from '@/components/common/Modals/CompareModal';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { selectDocument } from '@/store/document.slice';
import { selectCurrentMode, setCurrentConfig } from '@/store/editor.slice';
import { modalOpenState, selectRights } from '@/store/ui.slice';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { DropdownItem } from 'reactstrap';
import styled from 'styled-components';
import { useContext } from 'react';
import { DocumentContext } from '@/context/DocumentContext';

export const CompareIcon = styled.i`
  transform: rotate(90deg);
`;

export function switchCompareLocal(isCompareCurrentMode: boolean): string {
  return isCompareCurrentMode ? 'header.button.exitcompare' : 'header.button.compare';
}

export function CompareButton({ isDropdownItem = false, disabled }: { isDropdownItem?: boolean; disabled: boolean }) {
  const dispatch = useDispatch();
  const isUnderLgSize = useMediaQuery([['(min-width: 992px)', false]], true);

  const { canWrite } = useSelector(selectRights);
  const { isReadOnly } = useSelector(selectDocument);

  const {document: documentContent, setDocument} = useContext(DocumentContext);
  const restore = () => {
    dispatch(setCurrentConfig({ mode: canWrite || !isReadOnly ? 'defaultMode' : 'readOnly' }));
    setDocument(documentContent ?? null);
  };
  const openCompareModal = () => dispatch(modalOpenState({ modalId: 'Compare', openState: true }));

  const currentMode = useSelector(selectCurrentMode);
  const label = switchCompareLocal(currentMode === 'compareMode');

  return (
    <>
      <CompareModal />

      {!isDropdownItem ? (
        <button
          type="button"
          className={`btn btn-flat-secondary btn-lg ${isUnderLgSize ? '' : 'btn-icon-text'}`}
          onClick={currentMode === 'compareMode' ? restore : openCompareModal}
          disabled={disabled}
        >
          <CompareIcon className="icon">view_agenda</CompareIcon>
          <span className="d-none d-xl-inline">
            <FormattedMessage id={label} />
          </span>
        </button>
      ) : (
        <DropdownItem
          className="btn btn-flat-secondary btn-lg btn-icon-text"
          onClick={currentMode === 'compareMode' ? restore : openCompareModal}
          disabled={disabled}
        >
          <CompareIcon className="icon">view_agenda</CompareIcon>
          <span>
            <FormattedMessage id={label} />
          </span>
        </DropdownItem>
      )}
    </>
  );
}
