import type React from 'react';
import { type ChangeEvent, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Modal } from '@/components/common/Modals/Modal';
import { modalOpenState, selectModal } from '@/store/ui.slice';
import { useDispatch, useSelector } from 'react-redux';

const langs = [
  'English',
  'French',
  'German',
  'Polish',
  'Other',
  'Japanese',
  'Bulgarian',
  'Croatian',
  'Czech',
  'Danish',
  'Dutch',
  'Estonian',
  'Finnish',
  'Greek',
  'Hungarian',
  'Icelandic',
  'Irish',
  'Italian',
  'Latvian',
  'Lithuanian',
  'Maltese',
  'Norwegian',
  'Portuguese',
  'Romanian',
  'Slovak',
  'Slovenian',
  'Spanish',
  'Swedish',
  'Serbian',
  'Russian',
  'Chinese',
  'TraditionalChinese',
];

type ReaderLoadUrlModalProps = {
  onClick: (lang: string, tradeRef: string) => void;
  isLoading: boolean;
};

export const ReaderLoadUrlModal = ({ onClick, isLoading }: ReaderLoadUrlModalProps) => {
  const dispatch = useDispatch();
  const open = useSelector(selectModal('ReaderLoadURL'));

  const [openState, setOpenState] = useState(open);
  const handleCloseButton = () => {
    setOpenState(false);
  };

  useEffect(() => {
    setOpenState(open);
  }, [open]);

  const [tradeRef, setTradeRef] = useState('');
  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTradeRef(event.target.value);
  };

  const [lang, setLang] = useState('English');
  const handleLanguageChange = (selectedOption: { target: { value: React.SetStateAction<string> } }) => {
    setLang(selectedOption.target.value);
  };

  const handleReviewClick = () => {
    onClick(lang, tradeRef);
  };

  useEffect(() => {
    dispatch(modalOpenState({ modalId: 'ReaderLoadURL', openState }));
  }, [dispatch, openState]);

  return (
    <Modal open={openState}>
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content shadow-max">
          <div className="modal-header">
            <h3 className="modal-title" id="exampleModalLabel1">
              <FormattedMessage id="modal.load.title" />
            </h3>
            <button
              type="button"
              className="close icon"
              data-dismiss="modal"
              aria-label="Close"
              onClick={handleCloseButton}
            >
              close
            </button>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <label>
                <FormattedMessage id="modal.load.tradeIdInput" />
              </label>
              <div className="input-group input-group-lg">
                <div className="input-group-prepend input-group-merged">
                  <i className="icon">search</i>
                </div>
                <input
                  type="search"
                  className="form-control form-control-lg"
                  id="exampleFormControlInput1"
                  placeholder="XYZ-123456"
                  onChange={handleSearchChange}
                />
              </div>
            </div>
            <div className="form-group">
              <label>
                <FormattedMessage id="modal.load.language" />
              </label>
              <select className="form-control form-control-lg" onChange={handleLanguageChange}>
                {langs.map((value) => (
                  <option key={value}>{value}</option>
                ))}
              </select>
            </div>

            <button
              type="button"
              className={`btn btn-lg btn-primary w-100 mb-1 mt-2 ${isLoading ? 'disabled' : ''}`}
              data-dismiss="modal"
              onClick={handleReviewClick}
            >
              {isLoading ? (
                <>
                  <div
                    className="spinner-border spinner-border-sm mr-1"
                    style={{ height: '1rem', width: '1rem' }}
                    role="status"
                  />
                  <FormattedMessage id="modal.load.reviewing" />
                </>
              ) : (
                <>
                  <i className="icon icon-md-outline mr-2">task</i>
                  <FormattedMessage id="modal.load.review" />
                </>
              )}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
