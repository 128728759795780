import { useIntl } from 'react-intl';
import { DropdownItem } from 'reactstrap';
import type { Exportable } from '../exportFile';

type ExportButtonProps = {
  type: Exportable;
  onClick: () => void;
  isDisabled: boolean;
};

export function ExportItem({ type, onClick, isDisabled }: ExportButtonProps) {
  const { formatMessage } = useIntl();
  const label = `${formatMessage({ id: 'header.button.export.export_as' })} .${type}`;

  return (
    <DropdownItem onClick={onClick} disabled={isDisabled}>
      {label}
    </DropdownItem>
  );
}
