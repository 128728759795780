import { Conditional } from '@/components/common/Conditional';
import { ActionsWrapper } from '@/components/routes/Editor/common/ActionsWrapper/ActionsWrapper';
import { ChasingButtonsWrapper } from '@/components/routes/Editor/common/ChasingButtonsWrapper/ChasingButtonsWrapper';
import { ExportDropdown } from '@/components/routes/Editor/common/ExportButton/ExportDropdown';
import { FileInfoDisplay } from '@/components/routes/Editor/common/FileInfoDisplay';
import { LoadUrlButton } from '@/components/routes/Editor/common/LoadUrlButton';
import { SaveButtonsWrapper } from '@/components/routes/Editor/common/SaveButtonsWrapper/SaveButtonsWrapper';
import { getExportDropValues } from '@/components/routes/Editor/common/exportFile';
import { useDocTypeObj } from '@/hooks/useDocType';
import { selectDocument } from '@/store/document.slice';
import { selectEditorState } from '@/store/editor.slice';
import { selectRights } from '@/store/ui.slice';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export function ReviewXoneEditorHeader() {
  const { documentType, fileName, isReadOnly } = useSelector(selectDocument);

  const {
    Confirmation,
    PreConfirmation,
    ReadOnlyDocument,
    Chasing,
    Affirmation,
    PreMatching,
    FlowAdvice,
    TermSheet,
    IssuingDocumentation,
  } = useDocTypeObj(documentType);

  const isChasingDocumentType = documentType?.trim().toLowerCase() === 'chasing';

  const exportDropValues = getExportDropValues(documentType);

  const { canWrite } = useSelector(selectRights);

  const shouldDisplayCompare =
    Confirmation || ReadOnlyDocument || FlowAdvice || IssuingDocumentation || PreMatching || TermSheet;
  const shouldDisplayAttachment =
    fileName !== undefined && (PreConfirmation || Chasing || Affirmation || PreMatching || TermSheet);
  const actionList = ['compare', 'attachment'];
  const actionsToDisplay = actionList.filter(
    (action) => (action === 'compare' && shouldDisplayCompare) || (action === 'attachment' && shouldDisplayAttachment),
  );
  // const actionsToDisplay = actionList.filter((action) => action);

  const { editor, currentContent } = useSelector(selectEditorState);
  const [draftXmlDocument, setDraftXmlDocument] = useState<string | null>(null);

  useEffect(() => {
    if (currentContent) {
      const getdraftXmlDocument = async () => {
        const result = (await editor?.saveToString())?.draftXML ?? null;
        setDraftXmlDocument(result);
      };

      getdraftXmlDocument();
    }
  }, [currentContent, editor]);

  return (
    <>
      <div className="col d-flex align-items-center">
        <LoadUrlButton />
        <FileInfoDisplay />

        {actionsToDisplay.length > 0 && <ActionsWrapper actions={actionsToDisplay} />}

        <div className="ml-auto d-flex">
          <ExportDropdown exportValues={exportDropValues} />

          <Conditional condition={canWrite && !isReadOnly}>
            {!isChasingDocumentType ? (
              <SaveButtonsWrapper draftXmlDocument={draftXmlDocument} />
            ) : (
              <ChasingButtonsWrapper draftXmlDocument={draftXmlDocument} />
            )}
          </Conditional>
        </div>
      </div>
    </>
  );
}
