import { ExportButton } from '@/components/routes/Editor/common/ExportButton/ExportButton';
import { FileInfoDisplay } from '@/components/routes/Editor/common/FileInfoDisplay';
import { ReaderLoadUrlButton } from '@/components/routes/Editor/common/ReaderLoadUrlButton';
import React from 'react';

export function PreviewXoneHeader({ documentBlob }: { documentBlob: Blob | null }) {
  return (
    <div className="col d-flex align-items-center">
      <ReaderLoadUrlButton />
      <FileInfoDisplay />

      <div className="ml-auto">
        <ExportButton document={documentBlob} />
      </div>
    </div>
  );
}
