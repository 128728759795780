import { Conditional } from '@/components/common/Conditional';
import { UserContext } from '@/context/UserContext';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { useLazySaveChasingQuery } from '@/store/document.api';
import { selectChasing } from '@/store/document.slice';
import { selectEditor } from '@/store/editor.slice';
import { isPlainChasingInfos } from '@/types/fileInfos';
import { useContext, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

type SaveChasingButtonProps = { draftXmlDocument: string | null };

export function SaveChasingButton({ draftXmlDocument }: SaveChasingButtonProps) {
  const chasing = useSelector(selectChasing);
  const editor = useSelector(selectEditor);

  const isUnderXlScreenSize = useMediaQuery([['(min-width: 1200px)', false]], true);
  const extraClasses = useMemo(() => (isUnderXlScreenSize ? ' mr-1' : ' btn-text-icon mr-2'), [isUnderXlScreenSize]);

  const { sgConnect } = useContext(UserContext).globalContext;

  const [triggerSave, saveResponse] = useLazySaveChasingQuery();

  const isDisabled = !saveResponse.isUninitialized;

  const loading = saveResponse.isLoading || saveResponse.isFetching;

  const saveChasing = async () => {
    const editorDocument = await editor?.saveToString();
    const draftXmlDocumentToSave = editorDocument?.draftXML ?? draftXmlDocument;

    if (!draftXmlDocumentToSave || !isPlainChasingInfos(chasing)) {
      return;
    }

    triggerSave({
      ...chasing,
      workingDocument: draftXmlDocumentToSave,
      userBdrInitials: sgConnect?.getIdTokenClaims()?.sub.split('@')[0],
    });
  };

  return (
    <>
      <button
        className={`btn sgbs-btn-default btn-lg${extraClasses}`}
        type="button"
        onClick={saveChasing}
        disabled={isDisabled}
      >
        <Conditional condition={!isUnderXlScreenSize}>
          {loading ? (
            <FormattedMessage id="header.button.chasing.saving" />
          ) : (
            <FormattedMessage id="header.button.chasing.save" />
          )}
        </Conditional>
        <i className="icon">save</i>
      </button>
    </>
  );
}
