import { Conditional } from '@/components/common/Conditional';
import { AttacherModal } from '@/components/common/Modals/AttacherModal';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { useAppDispatch } from '@/store/store';
import { modalOpenState } from '@/store/ui.slice';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

export function SaveChasingModalButton() {
  const dispatch = useAppDispatch();

  const isUnderXlScreenSize = useMediaQuery([['(min-width: 1200px)', false]], true);
  const extraClasses = useMemo(() => (isUnderXlScreenSize ? ' mr-1' : ' btn-text-icon mr-2'), [isUnderXlScreenSize]);

  const handleClick = async () => {
    dispatch(modalOpenState({ modalId: 'Attacher', openState: true }));
  };

  return (
    <>
      <AttacherModal />
      <button className={`btn btn-info btn-lg${extraClasses}`} type="button" onClick={handleClick}>
        <Conditional condition={!isUnderXlScreenSize}>
          <FormattedMessage id={'header.button.chasing.attach'} />
        </Conditional>
        <i className="icon">save</i>
      </button>
    </>
  );
}
