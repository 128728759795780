import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { Modal } from '@/components/common/Modals/Modal';

import { selectDocument } from '@/store/document.slice';
import { saveNstoreDocument } from '@/store/document.thunk';
import { selectEditor } from '@/store/editor.slice';
import type { AppDispatch } from '@/store/store';
import { modalOpenState, selectLoading, selectModal } from '@/store/ui.slice';

export const isSettlementRelatedDocument = (docType: string) =>
  // settlement related documents : Prematching, Fixing, Payment, Payment remainder
  // https://sgithub.fr.world.socgen/XOne/XOne/blob/PROD60/src/Framework/TOne/Correspondence/Data/Enums/DocumentType.cs
  ['PreMatching', 'FlowAdvice', 'FlowCorrespondence', 'Fixing', 'Payment', 'PaymentReminder']
    .map((t) => t.toLowerCase())
    .includes(docType.toLowerCase());

export const SaveAndStoreModal = ({ draftXmlDocument }: { draftXmlDocument: string | null }) => {
  const [comment, setComment] = React.useState('');

  const dispatch = useDispatch<AppDispatch>();
  const open = useSelector(selectModal('SaveNStore'));
  const file = useSelector(selectDocument);
  const loading = useSelector(selectLoading('SaveNStore'));
  const editor = useSelector(selectEditor);

  const intl = useIntl();

  const saveNstore = async () => {
    const { assetClass, tradeReference, documentId, documentType } = file;
    const hasFileInfos = assetClass !== '' && tradeReference !== '' && documentId && documentType !== '';

    const editorDocument = await editor?.saveToString();
    const draftXmlDocumentToStore = editorDocument?.draftXML ?? draftXmlDocument;

    if (hasFileInfos && draftXmlDocumentToStore) {
      dispatch(
        saveNstoreDocument({
          assetClass,
          tradeReference,
          documentId,
          documentType,
          workingDocument: draftXmlDocumentToStore,
          actionName: 'Store',
          actionComment: comment,
        }),
      );
    }
  };

  const onCommentChange: React.ChangeEventHandler<HTMLTextAreaElement> = (event) => {
    if (event.currentTarget.value !== comment) {
      setComment(event.currentTarget.value);
    }
  };

  return (
    <Modal open={open}>
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content shadow-max">
          <div className="modal-header d-flex align-items-center p-2">
            <h3 className="modal-title pl-3 pt-2">
              <FormattedMessage id="modal.store.title" />
            </h3>
            <button
              type="button"
              className="btn btn-xl btn-flat-secondary ml-auto"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => dispatch(modalOpenState({ modalId: 'SaveNStore', openState: false }))}
            >
              <i className="icon">close</i>
            </button>
          </div>
          <div className="modal-body px-4 pb-0">
            <div>
              <FormattedMessage id="modal.store.text" />
            </div>
            <div className="mt-3">
              <label className="text-secondary" htmlFor="modal.store.comment">
                <FormattedMessage id="modal.store.comment" />
              </label>
              <textarea
                className="form-control form-control-lg"
                id="modal.store.comment"
                placeholder={intl.formatMessage({
                  id: 'modal.store.comment_placeholder',
                })}
                onChange={onCommentChange}
              />
            </div>
          </div>
          <div className="modal-footer p-4">
            <div className="btn-group m-0">
              <button
                type="button"
                className="btn btn-lg btn-flat-secondary m-0 mr-1"
                data-dismiss="modal"
                onClick={() => dispatch(modalOpenState({ modalId: 'SaveNStore', openState: false }))}
              >
                <FormattedMessage id="modal.store.cancel" />
              </button>
              <button
                type="button"
                className={`btn btn-lg btn-info m-0 ${loading ? 'disabled' : ''}`}
                data-dismiss="modal"
                onClick={saveNstore}
              >
                <FormattedMessage id={`modal.store.ok${loading ? 'ing' : ''}`} />
                {loading ? (
                  <div
                    className="spinner-border spinner-border-sm mr-1"
                    style={{ height: '1rem', width: '1rem' }}
                    role="status"
                  />
                ) : null}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
