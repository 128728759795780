import '@/components/layout/layout.css';

import { Footer } from '@/components/layout/Footer';
import { Header } from '@/components/layout/Header';
import { Outlet, useLoaderData } from 'react-router';
import type { Rights } from '@/store/ui.slice';
import type { FileInfo } from '@/store/document.slice';
import type { ChasingInfo } from '@/types/fileInfos';
import { DocumentProvider } from '@/context/DocumentContext';
import { Suspense } from 'react';
import { Sgb4Spinner } from '@/components/common/Sgb4Spinner';

type QueryParameters = {
  isredraft: boolean;
  isoriginal: boolean;
  isbomanager: boolean;
  SkipReviewCase: boolean;
  isreadonly: boolean;
  noFlag: boolean;
};

type LoaderData = {
  fileParameters: FileInfo | ChasingInfo;
  queryParameters: QueryParameters;
  rightsList: Rights;
};

export function Layout() {
  // TODO ABO, refine loader data type
  const { fileParameters, queryParameters, rightsList } = useLoaderData() as LoaderData;

  return (
    <Suspense
      fallback={
        <div className="m-5 d-flex justify-content-center">
          <Sgb4Spinner />
        </div>
      }
    >
      <div className="layout">
        <Header />

        <DocumentProvider>
          <Outlet context={{fileParameters, queryParameters, rightsList}} />
        </DocumentProvider>

        <Footer />
      </div>
    </Suspense>
  );
}
