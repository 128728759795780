import { LoadUrlModal } from '@/components/common/Modals/LoadURLModal';
import { modalOpenState } from '@/store/ui.slice';
import { useDispatch } from 'react-redux';

export function LoadUrlButton() {
  const dispatch = useDispatch();
  const openLoadURLModal = () => dispatch(modalOpenState({ modalId: 'LoadURL', openState: true }));

  return (
    <>
      <LoadUrlModal />

      <button type="button" className="btn sgbs-btn-default btn-lg " onClick={openLoadURLModal}>
        <i className="icon">folder_open</i>
      </button>
    </>
  );
}
