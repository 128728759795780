import { download } from '@/components/routes/Editor/common/exportFile';
import { selectDocument } from '@/store/document.slice';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

type ExportButtonContainerProps = {
  document: Blob | null;
};

export function ExportButton({ document }: ExportButtonContainerProps) {
  const file = useSelector(selectDocument);
  const extension = document?.type === 'text/html' ? 'html' : 'pdf';

  function exportPDF() {
    if (document) {
      download(document, `${file?.documentType}_${file?.tradeReference ?? file?.documentId}.${extension}`);
    }
  }

  return (
    <div className="bg-lvl1 d-none d-xl-flex justify-content-end">
      <button
        className="btn btn-flat-secondary btn-lg btn-text-icon mr-2"
        type="button"
        onClick={exportPDF}
        disabled={!document}
      >
        <FormattedMessage id="header.button.export" />
        <i className="icon">ios_share</i>
      </button>
    </div>
  );
}
