import styled from 'styled-components';
import type { PropsWithChildren } from 'react';
import { HtmlReader } from '@/components/routes/Editor/common/HtmlReader';
import { PDFReader } from '@/components/routes/Editor/common/PDFReader';
import { Conditional } from '@/components/common/Conditional';
import { SubHeader } from '@/components/layout/SubHeader';

const ContentContainer = styled.div`
    display: grid;
    height: 100%;
    grid-template-areas: "sub-header" "content";
    grid-template-rows: 58px 1fr;
    max-height: 100%;
    overflow: auto;
`;

type ReaderProps = {
  isLoading: boolean;
  isHtmlBlob: boolean;
  documentBlob: Blob | null;
};

// TODO ABO - remove documentBlob and transfer responsibility to children
export function Reader({ children, isLoading, isHtmlBlob, documentBlob }: PropsWithChildren<ReaderProps>) {
  return (
    <ContentContainer>
      <SubHeader isLoading={isLoading}>{children}</SubHeader>

      <div className="bg-lvl4 h-100 overflow-auto">
        <Conditional condition={!isLoading && documentBlob !== null}>
          {isHtmlBlob ? <HtmlReader documentBlob={documentBlob!} /> : <PDFReader document={documentBlob} />}
        </Conditional>
      </div>
    </ContentContainer>
  );
}
