import { SaveAndSendModal } from '@/components/common/Modals/SaveAndSendModal';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { selectDocument } from '@/store/document.slice';
import { modalOpenState, selectIsConsumed, selectTimedout } from '@/store/ui.slice';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { getLocal } from './utils';

export function SaveAndSendModalButton({ draftXmlDocument }: { draftXmlDocument: string | null }) {
  const dispatch = useDispatch();

  const { documentType } = useSelector(selectDocument);

  const isUnderXlScreenSize = useMediaQuery([['(min-width: 1200px)', false]], true);
  const extraClasses = useMemo(() => (isUnderXlScreenSize ? '' : ' btn-text-icon'), [isUnderXlScreenSize]);

  function handleClick() {
    dispatch(modalOpenState({ modalId: 'SaveNSend', openState: true }));
  }

  const timedout = useSelector(selectTimedout);
  const sendConsumed = useSelector(selectIsConsumed('SaveNSend'));
  const isDisabled = timedout || sendConsumed;

  return (
    <>
      <SaveAndSendModal draftXmlDocument={draftXmlDocument} />

      <button
        className={`btn btn-info btn-lg${extraClasses}`}
        type="button"
        onClick={handleClick}
        disabled={isDisabled}
      >
        <span className="d-none d-xl-inline">
          <FormattedMessage id={`modal.send.save_send${getLocal(documentType)}`} />
        </span>
        <i className="icon">send</i>
      </button>
    </>
  );
}
