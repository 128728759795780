import { modalOpenState, setTimedout } from '@/store/ui.slice';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

const oneMinute = 1000 * 60;
const oneHour = oneMinute * 60;

export function useSetTimeout(isDocument: boolean, isEditor = true) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (isDocument && isEditor) {
      const idModalTimeout = setTimeout(
        () => dispatch(modalOpenState({ modalId: 'TimeOut', openState: true })),
        oneHour + oneMinute * 50,
      );
      const idSaveTimeout = setTimeout(() => dispatch(setTimedout(true)), oneHour * 2);

      return () => {
        clearTimeout(idModalTimeout);
        clearTimeout(idSaveTimeout);
      };
    }
  }, [isDocument, isEditor]);
}
