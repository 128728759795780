import { ConcurencyModal } from '@/components/common/Modals/ConcurencyModal';
import { TimeoutModal } from '@/components/common/Modals/TimeoutModal';
import { useLoadSmartDxEditor } from '@/components/routes/Editor/SgDocs/useLoadSmartDxEditor';
import { useSetTimeout } from '@/components/routes/Editor/Xone/PreviewXone/hooks/useSetTimeout';
import { selectEditor } from '@/store/editor.slice';
import { selectRights } from '@/store/ui.slice';
import { isDefined } from '@sgme/fp';
import { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { PreviewXoneEditorHeader } from './PreviewXoneEditorHeader';
import { Editor } from '@/components/layout/Editor';
import { DocumentContext } from '@/context/DocumentContext';

export function PreviewXoneEditor() {
  const { document } = useContext(DocumentContext);

  const editor = useSelector(selectEditor);

  useSetTimeout(isDefined(document), isDefined(editor));

  const { canRead, canWrite, canUseSmartDX } = useSelector(selectRights);

  useLoadSmartDxEditor([canRead, canWrite, canUseSmartDX]);

  useEffect(() => {
    if(isDefined(document) && editor) {
      editor.loadString(document, { openInPreview: false, trackChanges: true })
    }
  }, [document, editor]);

  return (
    <>
      <TimeoutModal />

      <Editor isLoading={!editor}>
        <ConcurencyModal />
        <PreviewXoneEditorHeader />
      </Editor>
    </>
  );
}
