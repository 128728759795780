import { ToasterContext } from '@/context/ToasterContext';
import { DocumentService } from '@/services/DocumentService';
import { selectDocument } from '@/store/document.slice';
import type { GlobalContext } from '@/types/global-context';
import { useContext, useEffect, useReducer } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

type LoadingState = {
  isLoading: boolean;
  isError: boolean;
  documentBlob: Blob | null;
};

type LoadingAction = {
  type: 'FETCH_INIT' | 'FETCH_SUCCESS' | 'FETCH_FAILURE';
  payload?: Blob;
};

export function useFetchDocumentBlob(document: string | null, globalContext: GlobalContext) {
  const intl = useIntl();
  const pushToast = useContext(ToasterContext).pushToastMessage;
  const { documentType } = useSelector(selectDocument);

  const initialState = { isLoading: false, isError: false, documentBlob: null };
  const [state, dispatch] = useReducer(documentBlobFetchReducer, initialState);

  useEffect(() => {
    if (document !== null) {
      const fetchBlob = async () => {
        let didCancel = false;
        dispatch({ type: 'FETCH_INIT' });

        try {
          const blob = await DocumentService.previewDocument(
            new AbortController().signal,
            globalContext,
            documentType || 'Confirmation',
            document,
          );

          if (!didCancel) {
            dispatch({ type: 'FETCH_SUCCESS', payload: blob });
          }
        } catch (error) {
          if (!didCancel) {
            pushToast({
              text: intl.formatMessage({ id: 'notification.export.failure' }),
              level: 'danger',
              icon: 'error_outline',
              type: 'alert',
            });
            dispatch({ type: 'FETCH_FAILURE' });
          }
        }

        return () => {
          didCancel = true;
        };
      };

      fetchBlob();
    }
  }, [document, documentType, globalContext, intl]);

  return state;
}

function documentBlobFetchReducer(state: LoadingState, action: LoadingAction): LoadingState {
  switch (action.type) {
    case 'FETCH_INIT':
      return { ...state, isLoading: true, isError: false };
    case 'FETCH_SUCCESS':
      return {
        isLoading: false,
        isError: false,
        documentBlob: action.payload ?? null,
      };
    case 'FETCH_FAILURE':
      return { ...state, isLoading: false, isError: true };
  }
}
