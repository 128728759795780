import { isIssuingRelatedDocument } from '@/components/common/Modals/SaveAndSendModal';

export function getLocal(documentType?: string): string {
  switch (documentType) {
    case 'FlowCorrespondence': {
      return '_flow_correspondence';
    }
    case 'Affirmation': {
      return '_affirmation';
    }
    case 'PreConfirmation': {
      return '_preconfirmation';
    }
    case 'FinalTerms': {
      return '_termsheet';
    }
    case 'TermSheet': {
      return '_termsheet';
    }
    default: {
      if (isIssuingRelatedDocument(documentType ?? '')) {
        return '_issuing';
      }

      return '';
    }
  }
}
