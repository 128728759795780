import type { ReactNode } from 'react';

export interface IConditionalProps {
  condition: boolean;
  fallback?: ReactNode;
  children: ReactNode | ReactNode[];
}

export const Conditional: React.FC<IConditionalProps> = ({ condition, fallback, children }) => {
  if (!condition) {
    return <>{fallback ?? null}</>;
  }

  return <>{children}</>;
};
