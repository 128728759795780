import { selectDocument } from '@/store/document.slice';
import React from 'react';
import { useSelector } from 'react-redux';

export function FileInfoDisplay() {
  const { tradeReference, documentType } = useSelector(selectDocument);

  return (
    <div
      className={`d-flex flex-column${documentType ? '' : ' justify-content-center'} mx-3`}
      data-e2e="file-info-display"
    >
      <span className="text-truncate mb-0" data-e2e="file-info-display-trade-reference">
        {tradeReference}
      </span>
      <h5 className="text-truncate mb-0" data-e2e="file-info-display-document-type">
        {documentType}
      </h5>
    </div>
  );
}
