import { isFileLoaded } from '@/components/routes/Editor/common/isFileLoaded';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { selectDocument } from '@/store/document.slice';
import { selectCurrentMode, setCurrentConfig } from '@/store/editor.slice';
import { modalOpenState } from '@/store/ui.slice';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import styled from 'styled-components';

const switchCompareLocal = (isCompareCurrentMode: boolean) =>
  isCompareCurrentMode ? 'header.button.exitcompare' : 'header.button.compare';

export function CompareButton() {
  const isUnderLgSize = useMediaQuery([['(min-width: 992px)', false]], true);

  return <>{!isUnderLgSize ? <CompareButtonItem classNameLabel="d-none d-xl-inline" /> : <DropdownHeaderCompare />}</>;
}

const CompareIcon = styled.i`
    transform: rotate(90deg);
  `;

function CompareButtonItem({ classNameLabel = '' }: { classNameLabel?: string }) {
  const dispatch = useDispatch();
  const openCompareModal = () => dispatch(modalOpenState({ modalId: 'Compare', openState: true }));

  const file = useSelector(selectDocument);
  const isDisabledCompareButton = !isFileLoaded(file);

  const currentMode = useSelector(selectCurrentMode);
  const restore = () => dispatch(setCurrentConfig({ mode: 'readOnly' }));

  return (
    <button
      type="button"
      disabled={isDisabledCompareButton}
      onClick={currentMode === 'compareMode' ? restore : openCompareModal}
      className="btn btn-flat-secondary btn-lg btn-icon-text"
    >
      <CompareIcon className="icon">view_agenda</CompareIcon>
      <span className={classNameLabel}>
        <FormattedMessage id={switchCompareLocal(currentMode === 'compareMode')} />
      </span>
    </button>
  );
}

function DropdownHeaderCompare() {
  const [openMenu, setOpenMenu] = useState<boolean>(false);

  function toggleMenu() {
    setOpenMenu((prevState) => !prevState);
  }

  return (
    <Dropdown isOpen={openMenu} toggle={toggleMenu}>
      <DropdownToggle className="btn btn-lg btn-link btn-flat-secondary border-0 ml-2">
        <i className="icon">more_vert</i>
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem>
          <CompareButtonItem />
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
}
