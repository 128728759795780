import { ReaderLoadUrlModal } from '@/components/common/Modals/readerModals/ReaderLoadURLModal';
import { DocumentContext } from '@/context/DocumentContext';
import { useLazyGetDocumentLangQuery } from '@/store/document.api';
import { setFile } from '@/store/document.slice';
import { setCurrentConfig } from '@/store/editor.slice';
import { modalOpenState } from '@/store/ui.slice';
import React, { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';

export function ReaderLoadUrlButton() {
  const dispatch = useDispatch();
  const openLoadURLModal = () => dispatch(modalOpenState({ modalId: 'ReaderLoadURL', openState: true }));

  const [trigger, response] = useLazyGetDocumentLangQuery();
  const { setDocument } = useContext(DocumentContext);

  useEffect(() => {
    if (response.data) {
      const SelectedContent = response.data.workingContent ?? response.data.originalContent ?? '';

      dispatch(modalOpenState({ modalId: 'ReaderLoadURL', openState: false }));
      dispatch(
        setFile({
          documentType: response.data.documentType ?? '',
          documentId: response.data.documentId,
          assetClass: response.data.assetClass ?? '',
          tradeReference: response.data.contractId ?? '',
          isReadOnly: response.data.isReadOnly,
        }),
      );
      dispatch(setCurrentConfig({ mode: response.data.isReadOnly ? 'readOnly' : 'defaultMode' }));

      setDocument(SelectedContent);
    }
  }, [dispatch, response.data, setDocument]);

  function handleClick(lang: string, tradeRef: string) {
    trigger({ language: lang, tradeReference: tradeRef });
  }

  return (
    <>
      <ReaderLoadUrlModal onClick={handleClick} isLoading={response.isLoading} />

      <button type="button" className="btn sgbs-btn-default btn-lg " onClick={openLoadURLModal}>
        <i className="icon">folder_open</i>
      </button>
    </>
  );
}
