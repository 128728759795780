import { AttachmentsModal } from '@/components/common/Modals/AttachmentsModal';
import { CompareModal } from '@/components/common/Modals/CompareModal';
import { AttachmentDropdownItem } from '@/components/routes/Editor/common/ActionsWrapper/AttachmentDropdownItem';
import { CompareDropdownItem } from '@/components/routes/Editor/common/ActionsWrapper/CompareDropdownItem';
import { isFileLoaded } from '@/components/routes/Editor/common/isFileLoaded';
import { selectDocument } from '@/store/document.slice';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';

export function ActionsDropdown({ actions }: { actions: string[] }) {
  const [openMenu, setOpenMenu] = useState<boolean>(false);

  function toggleMenu() {
    setOpenMenu((prevState) => !prevState);
  }

  const fileInfos = useSelector(selectDocument);

  const shouldDisplayCompare = actions.includes('compare');
  const shouldDisplayAttachment = actions.includes('attachment');

  return (
    <>
      <CompareModal />
      <AttachmentsModal />

      <Dropdown isOpen={openMenu} toggle={toggleMenu}>
        <DropdownToggle className="btn btn-lg btn-link btn-flat-secondary border-0 ml-2">
          <i className="icon">more_vert</i>
        </DropdownToggle>
        <DropdownMenu>
          {shouldDisplayCompare && <CompareDropdownItem disabled={!isFileLoaded(fileInfos)} />}
          {shouldDisplayAttachment && <AttachmentDropdownItem disabled={!isFileLoaded(fileInfos)} />}
        </DropdownMenu>
      </Dropdown>
    </>
  );
}
