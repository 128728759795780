import { isIssuingRelatedDocument } from '@/components/common/Modals/SaveAndSendModal';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { selectDocument } from '@/store/document.slice';
import { saveNsendDocument } from '@/store/document.thunk';
import { selectEditor } from '@/store/editor.slice';
import { useAppDispatch } from '@/store/store';
import { selectLoading, selectTimedout } from '@/store/ui.slice';
import { useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { getLocal } from './utils';

export function SaveAndSendButton({ draftXmlDocument }: { draftXmlDocument: string | null }) {
  const dispatch = useAppDispatch();

  const fileInfos = useSelector(selectDocument);
  const isIssuing = isIssuingRelatedDocument(fileInfos.documentType);
  const editor = useSelector(selectEditor);

  const isUnderXlScreenSize = useMediaQuery([['(min-width: 1200px)', false]], true);
  const extraClasses = useMemo(() => (isUnderXlScreenSize ? '' : ' btn-text-icon'), [isUnderXlScreenSize]);

  const timedout = useSelector(selectTimedout);

  const loading = useSelector(selectLoading('SaveNSend'));

  const saveAndSend = useCallback(async () => {
    const { assetClass, tradeReference, documentId, documentType } = fileInfos;
    const hasFileInfos = assetClass !== '' && tradeReference !== '' && documentId && documentType !== '';

    const editorDocument = await editor?.saveToString();
    const draftXmlDocumentToSave = editorDocument?.draftXML ?? draftXmlDocument;

    if (hasFileInfos && draftXmlDocumentToSave) {
      dispatch(
        saveNsendDocument({
          assetClass,
          tradeReference,
          documentId,
          documentType,
          workingDocument: draftXmlDocumentToSave,
          actionName: isIssuing ? 'Validate' : 'Send',
          actionComment: '',
        }),
      );
    }
  }, [fileInfos, editor, draftXmlDocument, dispatch, isIssuing]);

  return (
    <>
      <button className={`btn btn-info btn-lg${extraClasses}`} type="button" onClick={saveAndSend} disabled={timedout}>
        {loading ? (
          <span className="d-none d-xl-inline">
            <FormattedMessage id={`modal.send.save_sending${getLocal(fileInfos.documentType)}`} />
          </span>
        ) : (
          <span className="d-none d-xl-inline">
            <FormattedMessage id={`modal.send.save_send${getLocal(fileInfos.documentType)}`} />
          </span>
        )}
        <i className="icon">send</i>
      </button>
    </>
  );
}
