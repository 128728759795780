import { SaveAndStoreModal } from '@/components/common/Modals/SaveAndStoreModal';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { modalOpenState, selectIsConsumed, selectTimedout } from '@/store/ui.slice';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

export function SaveAndStoreButton({ draftXmlDocument }: { draftXmlDocument: string | null }) {
  const dispatch = useDispatch();

  const timedout = useSelector(selectTimedout);
  const storeConsumed = useSelector(selectIsConsumed('SaveNStore'));
  const sendConsumed = useSelector(selectIsConsumed('SaveNSend'));

  const isDisabled = timedout || storeConsumed || sendConsumed;

  const isUnderXlScreenSize = useMediaQuery([['(min-width: 1200px)', false]], true);
  const extraClasses = useMemo(() => (isUnderXlScreenSize ? ' mr-1' : ' btn-text-icon mr-2'), [isUnderXlScreenSize]);

  function handleClick() {
    dispatch(modalOpenState({ modalId: 'SaveNStore', openState: true }));
  }

  return (
    <>
      <SaveAndStoreModal draftXmlDocument={draftXmlDocument} />

      <button
        className={`btn sgbs-btn-default btn-lg${extraClasses}`}
        type="button"
        onClick={handleClick}
        disabled={isDisabled}
      >
        <span className="d-none d-xl-inline">
          <FormattedMessage id={'header.button.save_store'} />
        </span>
        <i className="icon">move_to_inbox</i>
      </button>
    </>
  );
}
