import React from 'react';
import { ExportButton } from '../../../common/ExportButton/ExportButton';

export function PreviewHeader({ document }: { document: Blob | null }) {
  return (
    <>
      <div className="col bg-lvl1">
        <div className="d-none d-xl-flex justify-content-end">
          {document !== null && <ExportButton document={document} />}
        </div>
      </div>
    </>
  );
}
