import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { Modal } from '@/components/common/Modals/Modal';
import { modalOpenState, selectModal } from '@/store/ui.slice';

export const TimeoutModal = () => {
  const dispatch = useDispatch();
  const open = useSelector(selectModal('TimeOut'));

  return (
    <Modal open={open}>
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content shadow-max" style={{ border: '1px solid var(--danger)' }}>
          <div className="modal-header">
            <h3 className="modal-title" style={{ color: 'var(--danger)' }}>
              <FormattedMessage id="modal.timeout.title" />
            </h3>
            <button
              type="button"
              className="close icon"
              data-dismiss="modal"
              style={{ color: 'var(--danger)' }}
              onClick={() => dispatch(modalOpenState({ modalId: 'TimeOut', openState: false }))}
            >
              close
            </button>
          </div>
          <div className="modal-body">
            <div className="text-secondary mb-3">
              <FormattedMessage id="modal.timeout.text" />
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-lg btn-primary"
              data-dismiss="modal"
              onClick={() => dispatch(modalOpenState({ modalId: 'TimeOut', openState: false }))}
            >
              <FormattedMessage id="modal.timeout.confirm" />
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
