import styled from 'styled-components';
import type { PropsWithChildren } from 'react';
import { EditorAnchor } from '@/helpers/SmartDXWrap';
import { SubHeader } from '@/components/layout/SubHeader';

const ContentContainer = styled.div`
    display: grid;
    height: 100%;
    grid-template-areas: "sub-header" "content";
    grid-template-rows: 58px 1fr;
`;

type EditorProps = {
  isLoading: boolean;
};

export function Editor({ isLoading, children }: PropsWithChildren<EditorProps>) {
  return (
    <ContentContainer>
      <SubHeader isLoading={isLoading}>{children}</SubHeader>

      <EditorAnchor editorIsLoading={isLoading} />
    </ContentContainer>
  );
}
