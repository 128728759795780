import { ConcurencyModal } from '@/components/common/Modals/ConcurencyModal';
import { useLoadSmartDxEditor } from '@/components/routes/Editor/SgDocs/useLoadSmartDxEditor';
import { EditorHeader } from '@/components/routes/Editor/Xone/Header';
import { useGetSgDocDocumentQuery } from '@/store/document.api';
import { selectEditor, setCompareState } from '@/store/editor.slice';
import type { UrlSgDocComparatorInfo } from '@/types/fileInfos';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Editor } from '@/components/layout/Editor';

export const SgDocsComparator = () => {
  useLoadSmartDxEditor([true, false, true]);

  const { sgDocsId, tradeRef } = useParams<Readonly<UrlSgDocComparatorInfo>>();
  const { data: sgDocContentDest } = useGetSgDocDocumentQuery({ documentId: sgDocsId });
  const { data: sgDocContentSrc } = useGetSgDocDocumentQuery({ documentId: tradeRef });

  const editor = useSelector(selectEditor);
  const dispatch = useDispatch();

  useEffect(() => {
    if (sgDocContentDest && sgDocContentSrc && editor) {
      dispatch(
        setCompareState({
          contentSrc: sgDocContentSrc,
          contentDest: sgDocContentDest,
        }),
      );
    }
  }, [dispatch, sgDocContentDest, editor, sgDocContentSrc]);

  return (
    <Editor isLoading={!editor}>
      <ConcurencyModal />
      <EditorHeader />
    </Editor>
  );
};
