import {
  CancelChasingButton,
  SendChasingButton,
} from '@/components/routes/Editor/common/ChasingButtonsWrapper/ChasingButtons/';
import React from 'react';

export function ChasingButtonsWrapper({ draftXmlDocument }: { draftXmlDocument: string | null }) {
  return (
    <>
      <CancelChasingButton draftXmlDocument={draftXmlDocument} />
      <SendChasingButton draftXmlDocument={draftXmlDocument} />
    </>
  );
}
