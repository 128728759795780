import type { PropsWithChildren } from 'react';
import { LoadingRenderComponent } from '@/components/common/LoadingRenderComponent';

type SubHeaderProps = {
  isLoading: boolean;
};

export const SubHeader = ({ isLoading, children }: PropsWithChildren<SubHeaderProps>) => (
  <LoadingRenderComponent isLoading={isLoading}>
    <div className="container-fluid px-3 px-lg-4 pb-2 pb-lg-3 border-bottom">
      <div className="row justify-content-start">{children}</div>
    </div>
  </LoadingRenderComponent>
);
