import { ConcurencyModal } from '@/components/common/Modals/ConcurencyModal';
import { OctaneEditorHeader } from '@/components/routes/Editor/Octane/OctaneEditor/OctaneEditorHeader';
import { useLoadSmartDxEditor } from '@/components/routes/Editor/SgDocs/useLoadSmartDxEditor';
import { useSetTimeout } from '@/components/routes/Editor/Xone/PreviewXone/hooks/useSetTimeout';
import {
  ReviewXoneEditorHeader,
} from '@/components/routes/Editor/Xone/ReviewXone/ReviewXoneEditor/ReviewXoneEditorHeader';
import { selectEditor } from '@/store/editor.slice';
import { selectOrigin, selectRights } from '@/store/ui.slice';
import { isDefined } from '@sgme/fp';
import { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { TimeoutModal } from '@/components/common/Modals/TimeoutModal';
import { Editor } from '@/components/layout/Editor';
import { DocumentContext } from '@/context/DocumentContext';

export function ReviewEditor() {
  const { document } = useContext(DocumentContext);

  const isOctane = useSelector(selectOrigin) === 'octane';

  const editor = useSelector(selectEditor);

  useSetTimeout(isDefined(document), isDefined(editor));

  const { canRead, canWrite, canUseSmartDX: canUseSmartDxDoc } = useSelector(selectRights);

  useLoadSmartDxEditor([canRead, canWrite, canUseSmartDxDoc]);

  useEffect(() => {
    if(isDefined(document) && editor) {
      editor.loadString(document, { openInPreview: false, trackChanges: true })
    }
  }, [document, editor]);

  return (
    <>
      <TimeoutModal />

      <Editor isLoading={!editor} >
        <ConcurencyModal />
        {isOctane ? <OctaneEditorHeader /> : <ReviewXoneEditorHeader />}
      </Editor>
    </>
  );
}
