import { Conditional } from '@/components/common/Conditional';
import { LoadUrlModal } from '@/components/common/Modals/LoadURLModal';
import { ExportDropdown } from '@/components/routes/Editor/common/ExportButton/ExportDropdown';
import { FileInfoDisplay } from '@/components/routes/Editor/common/FileInfoDisplay';
import { getExportDropValues } from '@/components/routes/Editor/common/exportFile';
import { useDocTypeObj } from '@/hooks/useDocType';
import { selectChasing, selectDocument } from '@/store/document.slice';
import { selectEditorState } from '@/store/editor.slice';
import { modalOpenState, selectQueryParams } from '@/store/ui.slice';
import { isPlainChasingInfos } from '@/types/fileInfos';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SaveButton } from '../OctaneButtons/SaveButton';
import { SaveChasingButton } from '../OctaneButtons/SaveChasingButton';
import { SaveChasingModalButton } from '../OctaneButtons/SaveChasingModalButton';
import { SendButton } from '../OctaneButtons/SendButton';
import { SendChasingButton } from '../OctaneButtons/SendChasingButton';

export function OctaneEditorHeader() {
  const dispatch = useDispatch();
  const chasing = useSelector(selectChasing);

  const { documentType } = useSelector(selectDocument);

  const { isbomanager } = useSelector(selectQueryParams);
  const { Confirmation, Invoice, MRB } = useDocTypeObj(documentType);

  const shouldDisplaySaveButton = Confirmation || Invoice;
  const shouldDisplaySendButton = ((Confirmation || Invoice) && (isbomanager || Invoice)) || MRB;

  const openLoadURLModal = () => dispatch(modalOpenState({ modalId: 'LoadURL', openState: true }));

  const exportDropValues = getExportDropValues(documentType);

  const { editor, currentContent } = useSelector(selectEditorState);
  const [draftXmlDocument, setDraftXmlDocument] = useState<string | null>(null);

  useEffect(() => {
    if (currentContent) {
      const getdraftXmlDocument = async () => {
        const result = (await editor?.saveToString())?.draftXML ?? null;
        setDraftXmlDocument(result);
      };

      getdraftXmlDocument();
    }
  }, [currentContent, editor]);

  return (
    <>
      <div className="bg-lvl1 col d-flex align-items-center">
        <LoadUrlModal />
        <button type="button" className="btn sgbs-btn-default btn-lg " onClick={openLoadURLModal}>
          <i className="icon">folder_open</i>
        </button>

        <FileInfoDisplay />

        <div className="ml-auto d-flex">
          <ExportDropdown exportValues={exportDropValues} />

          {shouldDisplaySaveButton && <SaveButton draftXmlDocument={draftXmlDocument} Confirmation={Confirmation} />}
          {shouldDisplaySendButton && <SendButton draftXmlDocument={draftXmlDocument} MRB={MRB} />}

          <Conditional condition={isPlainChasingInfos(chasing)}>
            <SaveChasingModalButton />
            <SaveChasingButton draftXmlDocument={draftXmlDocument} />
            <SendChasingButton draftXmlDocument={draftXmlDocument} />
          </Conditional>
        </div>
      </div>
    </>
  );
}
