import { ConcurencyModal } from '@/components/common/Modals/ConcurencyModal';
import { DocumentContext } from '@/context/DocumentContext';
import { UserContext } from '@/context/UserContext';
import { useFetchDocumentBlob } from '@/hooks/useFetchDocumentBlob';
import { useContext } from 'react';
import { PreviewXoneHeader } from './PreviewXoneHeader';
import { Reader } from '@/components/layout/Reader';

export function PreviewXoneReader() {
  const { globalContext } = useContext(UserContext);

  const { document } = useContext(DocumentContext);

  // const documentData =  new TextDecoder().decode(base64ToBytes(document));
  const { documentBlob, isLoading } = useFetchDocumentBlob(document, globalContext);

  const isHtmlBlob = documentBlob?.type === 'text/html';

  return (
    <>
      <Reader isLoading={isLoading} isHtmlBlob={isHtmlBlob} documentBlob={documentBlob}>
        <ConcurencyModal />
        <PreviewXoneHeader documentBlob={documentBlob} />
      </Reader>
    </>
  );
}
