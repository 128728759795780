import { isFileLoaded } from '@/components/routes/Editor/common/isFileLoaded';
import { logger } from '@/helpers/logger';
import { selectDocument } from '@/store/document.slice';
import { selectQueryParams } from '@/store/ui.slice';
import { useSgwtWidgets } from '@sgwt/sgwt-widgets-react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

export function UseTrackLoadingPage() {
  const { sgwtWebAnalytics } = useSgwtWidgets();

  const fileInfos = useSelector(selectDocument);
  const { isredraft, isoriginal, isbomanager, SkipReviewCase, isreadonly } = useSelector(selectQueryParams);

  useEffect(() => {
    if (!isFileLoaded(fileInfos)) {
      return;
    }

    sgwtWebAnalytics?.trackEvent(
      'Loading',
      `Document Editor loaded traderef: ${fileInfos.tradeReference}, docType: ${fileInfos.documentType} & docId: ${fileInfos.documentId} with flags isredraft: ${isredraft}, isoriginal: ${isoriginal}, isbomanager: ${isbomanager}, SkipReviewCase: ${SkipReviewCase} & isreadonly: ${isreadonly}`,
    );

    logger.logInformation(
      'Document Editor loaded traderef: {fileTradeRef}, docType: {fileDocType} & docId: {fileDocId} with flags isredraft: {isredraft}, isoriginal: {isoriginal}, isbomanager: {isbomanager}, SkipReviewCase: {SkipReviewCase} & isreadonly: {isreadonly}',
      fileInfos.tradeReference,
      fileInfos.documentType,
      fileInfos.documentId,
      isredraft,
      isoriginal,
      isbomanager,
      SkipReviewCase,
      isreadonly,
    );
  }, [isredraft, isoriginal, isbomanager, SkipReviewCase, isreadonly, fileInfos, sgwtWebAnalytics]);
}
