import React, { useContext } from 'react';

import type { FileInfo } from '@/store/document.slice';
import { DocumentContext } from '@/context/DocumentContext';

export const isFileLoaded = (maybeFile: FileInfo): maybeFile is Required<FileInfo> =>
  !!maybeFile.tradeReference && !!maybeFile.documentType && !!maybeFile.documentId;

export const useFileLoaded = (maybeFile: FileInfo): maybeFile is Required<FileInfo> => {
  const { document: content } = useContext(DocumentContext);
  const fileLoaded = React.useMemo(() => isFileLoaded(maybeFile), [maybeFile]);
  return fileLoaded && content !== undefined;
};

export const useFilePresent = (
  maybeFile: FileInfo,
  draftXmlDocument: string | null,
): maybeFile is Required<FileInfo> => {
  const fileLoaded = React.useMemo(() => isFileLoaded(maybeFile), [maybeFile]);
  return fileLoaded && draftXmlDocument !== undefined;
};
