import { isDefined } from '@sgme/fp';
import { useEffect, useState } from 'react';
import parse from 'html-react-parser';

export function HtmlReader({ documentBlob }: { documentBlob: Blob }) {
  const [htmlContent, setHtmlContent] = useState<string | React.JSX.Element | React.JSX.Element[]>('');

  useEffect(() => {
    const getHtmlData = async () => {
      const documentContent = await documentBlob?.text();

      if (isDefined(documentContent)) {
        const parser = new DOMParser();
        const htmlContent = parser.parseFromString(documentContent, "application/xml").body.innerHTML;

        setHtmlContent(parse(htmlContent));
      }
    };

    getHtmlData();
  }, [documentBlob]);

  return (
    <div className="bg-lvl4 mx-auto pt-3 pb-5" data-e2e="html-reader">
      <div className="bg-lvl1 shadow-sm w-50 mx-auto px-5">
        <div className="flex-center">{htmlContent}</div>
      </div>
    </div>
  );
}
