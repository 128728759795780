import { DocumentContext } from '@/context/DocumentContext';
import { UseTrackLoadingPage } from '@/hooks/useTrackLoadingPage';
import { selectChasing, selectDocument } from '@/store/document.slice';
import { setCurrentConfig } from '@/store/editor.slice';
import { selectOrigin, selectRights } from '@/store/ui.slice';
import { isPlainChasingInfos } from '@/types/fileInfos';
import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReviewReader } from '@/components/routes/Editor/Xone/ReviewXone/ReviewXoneReader/ReviewReader';
import { useFetchDocumentChasing } from '@/components/routes/Editor/common/useFetchDocumentChasing';
import { useFetchDocument } from '@/components/routes/Editor/common/useFetchDocument';
import { createToast } from '@/components/common/ToasterProvider';
import { useLocation } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { ReviewEditor } from '@/components/routes/Editor/Xone/ReviewXone/ReviewXoneEditor/ReviewEditor';

export function ReviewPage() {
  const dispatch = useDispatch();

  const { setDocument } = useContext(DocumentContext);
  const { pathname } = useLocation();

  const fileInfos = useSelector(selectDocument);
  const chasing = useSelector(selectChasing);
  const isChasing = chasing.documentType === 'CHASING' && isPlainChasingInfos(chasing);

  const origin = useSelector(selectOrigin);
  const isXone = origin === 'xone';
  const isKb = origin === 'kb'
  const isOctane = origin === 'octane';

  const { canWrite, canUseSmartDX } = useSelector(selectRights);

  const [document, isFileLoaded] = !isChasing ? useFetchDocument(isXone || isKb) : useFetchDocumentChasing(chasing);

  useEffect(() => {
    if(isFileLoaded) {
      setDocument(document);
    }
  }, [isFileLoaded, document, setDocument]);

  UseTrackLoadingPage();

  useEffect(() => {
    dispatch(setCurrentConfig({ mode: (fileInfos.isReadOnly || !canWrite) && !isOctane ? 'readOnly' : 'defaultMode' }));
  }, [canWrite, dispatch, fileInfos.isReadOnly, isOctane]);

  // TODO ABO, review what to do if document is null
  if(pathname !== '/review' && document === null) {
    if(isFileLoaded) {
      createToast({
        text: <FormattedMessage id="smartdx.saveToString.no_doc" />,
        level: 'danger',
        icon: 'error_outline',
        type: 'alert',
      });
    }

    return null;
  }

  return canUseSmartDX ? <ReviewEditor /> : <ReviewReader />;
}
