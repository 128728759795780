import { CompareModal } from '@/components/common/Modals/CompareModal';
import { CompareIcon, switchCompareLocal } from '@/components/routes/Editor/common/ActionsWrapper/CompareButton';
import { selectDocument } from '@/store/document.slice';
import { selectCurrentMode, setCurrentConfig } from '@/store/editor.slice';
import { modalOpenState, selectRights } from '@/store/ui.slice';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { DropdownItem } from 'reactstrap';
import { useContext } from 'react';
import { DocumentContext } from '@/context/DocumentContext';

export function CompareDropdownItem({ disabled }: { disabled: boolean }) {
  const dispatch = useDispatch();

  const { canWrite } = useSelector(selectRights);
  const { isReadOnly } = useSelector(selectDocument);

  const { document: documentContent, setDocument } = useContext(DocumentContext);
  const restore = () => {
    dispatch(setCurrentConfig({ mode: canWrite || !isReadOnly ? 'defaultMode' : 'readOnly' }));
    setDocument(documentContent ?? null);
  };
  const openCompareModal = () => dispatch(modalOpenState({ modalId: 'Compare', openState: true }));

  const currentMode = useSelector(selectCurrentMode);
  const label = switchCompareLocal(currentMode === 'compareMode');

  return (
    <>
      <CompareModal />

      <DropdownItem
        className="btn btn-flat-secondary btn-lg btn-icon-text"
        onClick={currentMode === 'compareMode' ? restore : openCompareModal}
        disabled={disabled}
      >
        <CompareIcon className="icon">view_agenda</CompareIcon>
        <span>
          <FormattedMessage id={label} />
        </span>
      </DropdownItem>
    </>
  );
}
