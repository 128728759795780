import { type SetStateAction, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { Modal } from '@/components/common/Modals/Modal';

import { useLazyGetDocumentLangQuery } from '@/store/document.api';
import { setFile } from '@/store/document.slice';
import { selectEditor, setCurrentConfig } from '@/store/editor.slice';
import { modalOpenState, selectModal } from '@/store/ui.slice';
import { useSgwtWidgets } from '@sgwt/sgwt-widgets-react';
import { DocumentContext } from '@/context/DocumentContext';

const langs = [
  'English',
  'French',
  'German',
  'Polish',
  'Other',
  'Japanese',
  'Bulgarian',
  'Croatian',
  'Czech',
  'Danish',
  'Dutch',
  'Estonian',
  'Finnish',
  'Greek',
  'Hungarian',
  'Icelandic',
  'Irish',
  'Italian',
  'Latvian',
  'Lithuanian',
  'Maltese',
  'Norwegian',
  'Portuguese',
  'Romanian',
  'Slovak',
  'Slovenian',
  'Spanish',
  'Swedish',
  'Serbian',
  'Russian',
  'Chinese',
  'TraditionalChinese',
];

export const LoadUrlModal = () => {
  const dispatch = useDispatch();
  const { sgwtWebAnalytics } = useSgwtWidgets();
  const { setDocument } = useContext(DocumentContext)

  const open = useSelector(selectModal('LoadURL'));
  const editor = useSelector(selectEditor);

  const [tradeRef, setTradeRef] = useState('');
  const [lang, setLang] = useState('English');

  const handleChange = (selectedOption: { target: { value: SetStateAction<string> } }) => {
    setLang(selectedOption.target.value);
  };

  const [trigger, response] = useLazyGetDocumentLangQuery();

  const inputFile = useRef<HTMLInputElement | null>(null);

  const closeModal = useCallback(() => {
    dispatch(modalOpenState({ modalId: 'LoadURL', openState: false }));
  }, [dispatch]);

  const handleReviewDocumentClick = () => {
    trigger({ language: lang, tradeReference: tradeRef });
  };

  const openFile = () => {
    if (inputFile.current) {
      inputFile.current.click();
    }
  };

  const openFileChosen = () => {
    if (inputFile?.current?.files?.[0]) {
      const reader = new FileReader();

      closeModal();

      reader.onload = (event) => {
        const text = event.target?.result as string;
        editor?.loadString(text, { openInPreview: false, trackChanges: true });
        dispatch(
          setFile({
            documentType: '',
            documentId: '',
            assetClass: '',
            tradeReference: inputFile?.current?.files?.[0].name.split('.')[0] ?? '',
            fromPc: true,
          }),
        );
        closeModal();
        sgwtWebAnalytics?.trackEvent('Action', 'Document loaded from PC');
      };

      reader.readAsText(inputFile.current.files[0]);
    }
  };

  useEffect(() => {
    if (response.data) {
      const SelectedContent = response.data.workingContent ?? response.data.originalContent ?? null;

      closeModal();

      dispatch(
        setFile({
          documentType: response.data.documentType ?? '',
          documentId: response.data.documentId,
          assetClass: response.data.assetClass ?? '',
          tradeReference: response.data.contractId ?? '',
          isReadOnly: response.data.isReadOnly,
        }),
      );
      dispatch(setCurrentConfig({ mode: response.data.isReadOnly ? 'readOnly' : 'defaultMode' }));
      setDocument(SelectedContent);
    }
  }, [closeModal, dispatch, response.data, setDocument]);

  return (
    <Modal open={open}>
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content shadow-max">
          <div className="modal-header">
            <h3 className="modal-title" id="exampleModalLabel1">
              <FormattedMessage id="modal.load.title" />
            </h3>
            <button type="button" className="close icon" data-dismiss="modal" aria-label="Close" onClick={closeModal}>
              close
            </button>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <label>
                <FormattedMessage id="modal.load.tradeIdInput" />
              </label>
              <div className="input-group input-group-lg">
                <div className="input-group-prepend input-group-merged">
                  <i className="icon">search</i>
                </div>
                <input
                  type="search"
                  className="form-control form-control-lg"
                  id="exampleFormControlInput1"
                  placeholder="XYZ-123456"
                  onChange={(event) => setTradeRef(event.target.value)}
                />
              </div>
            </div>
            <div className="form-group">
              <label>
                <FormattedMessage id="modal.load.language" />
              </label>
              <select className="form-control form-control-lg" onChange={handleChange}>
                {langs.map((value) => (
                  <option key={value}>{value}</option>
                ))}
              </select>
            </div>

            <button
              type="button"
              className={`btn btn-lg btn-primary w-100 mb-1 mt-2 ${response.isLoading ? 'disabled' : ''}`}
              data-dismiss="modal"
              onClick={handleReviewDocumentClick}
            >
              {response.isLoading ? (
                <div
                  className="spinner-border spinner-border-sm mr-1"
                  style={{ height: '1rem', width: '1rem' }}
                  role="status"
                />
              ) : (
                <i className="icon icon-md-outline mr-2">task</i>
              )}
              <FormattedMessage id={`modal.load.review${response.isLoading ? 'ing' : ''}`} />
            </button>

            <button type="button" className="btn btn-lg sgbs-btn-default w-100" data-dismiss="modal" onClick={openFile}>
              <i className="icon icon-md mr-2">desktop_windows</i>
              <FormattedMessage id="modal.load.open_pc" />
            </button>
          </div>
        </div>
      </div>
      <input type="file" id="file" ref={inputFile} style={{ display: 'none' }} onChange={openFileChosen} />
    </Modal>
  );
};
