import { Conditional } from '@/components/common/Conditional';
import { UserContext } from '@/context/UserContext';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { useLazySaveOctaneQuery } from '@/store/document.api';
import { selectDocument } from '@/store/document.slice';
import { selectEditor } from '@/store/editor.slice';
import { useAppDispatch } from '@/store/store';
import { consumed, selectIsConsumed, selectTimedout } from '@/store/ui.slice';
import { useContext, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useFilePresent } from '../../common/isFileLoaded';

type SaveButtonProps = { draftXmlDocument: string | null; Confirmation: boolean };

export function SaveButton({ draftXmlDocument, Confirmation }: SaveButtonProps) {
  const dispatch = useAppDispatch();

  const fileInfos = useSelector(selectDocument);
  const editor = useSelector(selectEditor);

  const isUnderXlScreenSize = useMediaQuery([['(min-width: 1200px)', false]], true);
  const extraClasses = useMemo(() => (isUnderXlScreenSize ? ' mr-1' : ' btn-text-icon mr-2'), [isUnderXlScreenSize]);

  const { sgConnect } = useContext(UserContext).globalContext;

  const timedout = useSelector(selectTimedout);
  const saveConsumed = useSelector(selectIsConsumed('Save'));
  const sendConsumed = useSelector(selectIsConsumed('SendOctane'));
  const loaded = useFilePresent(fileInfos, draftXmlDocument);
  const isDisabled = !loaded || timedout || saveConsumed || sendConsumed;

  const [triggerSaveOctane, responseSendOctane] = useLazySaveOctaneQuery();

  const loading = responseSendOctane.isLoading || responseSendOctane.isFetching;

  const saveOctane = async () => {
    const { assetClass, tradeReference, documentId, documentType } = fileInfos;

    const editorDocument = await editor?.saveToString();
    const draftXmlDocumentToSave = editorDocument?.draftXML ?? draftXmlDocument;

    if (assetClass === '' || tradeReference === '' || !documentId || documentType === '') {
      return;
    }

    if (!draftXmlDocumentToSave) {
      return;
    }

    const res = await triggerSaveOctane({
      assetClass,
      tradeReference,
      documentId,
      documentType,
      action: Confirmation ? 'SaveToValidate' : 'Save',
      workingDocument: draftXmlDocumentToSave,
      userBdrInitials: sgConnect?.getIdTokenClaims()?.sub.split('@')[0],
    });
    if (res.isSuccess) {
      dispatch(consumed('Save'));
    }
  };
  return (
    <>
      <button
        className={`btn sgbs-btn-default btn-lg${extraClasses}`}
        type="button"
        onClick={saveOctane}
        disabled={isDisabled}
      >
        <Conditional condition={!isUnderXlScreenSize}>
          {loading ? (
            <FormattedMessage id={`header.button.octane.${Confirmation ? 'db_' : ''}saving`} />
          ) : (
            <FormattedMessage id={`header.button.octane.${Confirmation ? 'db_' : ''}save`} />
          )}
        </Conditional>
        <i className="icon">save</i>
      </button>
    </>
  );
}
