import { Conditional } from '@/components/common/Conditional';
import { SaveOctaneModal } from '@/components/common/Modals/saveOctaneModal';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { selectDocument } from '@/store/document.slice';
import { useAppDispatch } from '@/store/store';
import { modalOpenState, selectIsConsumed, selectTimedout } from '@/store/ui.slice';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useFilePresent } from '../../common/isFileLoaded';

type SendButtonProps = { draftXmlDocument: string | null; MRB: boolean };

export function SendButton({ MRB, draftXmlDocument }: SendButtonProps) {
  const dispatch = useAppDispatch();

  const fileInfos = useSelector(selectDocument);

  const isUnderXlScreenSize = useMediaQuery([['(min-width: 1200px)', false]], true);
  const extraClasses = useMemo(() => (isUnderXlScreenSize ? '' : ' btn-text-icon'), [isUnderXlScreenSize]);

  const timedout = useSelector(selectTimedout);
  const sendConsumed = useSelector(selectIsConsumed('SendOctane'));
  const loaded = useFilePresent(fileInfos, draftXmlDocument);
  const isDisabled = !loaded || timedout || sendConsumed;

  const handleClick = async () => {
    dispatch(modalOpenState({ modalId: 'SaveOctane', openState: true }));
  };
  return (
    <>
      <SaveOctaneModal draftXmlDocument={draftXmlDocument} />

      <button
        className={`btn btn-info btn-lg${extraClasses}`}
        type="button"
        onClick={handleClick}
        disabled={isDisabled}
      >
        <Conditional condition={!isUnderXlScreenSize}>
          {MRB ? (
            <FormattedMessage id={'header.button.octane.mail_send'} />
          ) : (
            <FormattedMessage id={'header.button.octane.save_send'} />
          )}
        </Conditional>
        <i className="icon">send</i>
      </button>
    </>
  );
}
