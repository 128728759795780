import type { LoaderFunctionArgs, Params, RouteObject } from 'react-router';
import { type ChasingInfo, isUrlChasingInfos } from '@/types/fileInfos';
import {
  getMergedPermssions,
  getRightsFromPayload,
  getUtilsPermissions,
} from '@/components/routes/Editor/common/Rights';
import type { FileInfo } from '@/store/document.slice';
import { Layout } from '@/components/layout/Layout';
import { LandingPage } from '@/components/routes/Landing';
import { PageNotFound } from '@/components/routes/PageNotFound';
import { isDefined } from '@sgme/fp';
import { ReviewPage } from '@/components/routes/Editor/common/ReviewPage';
import { PreviewPage } from '@/components/routes/Editor/Xone/PreviewXone/PreviewPage';
import { CommonWrapper } from '@/components/routes/Editor/common/CommonWrapper';
import { KBPreviewWrapper } from '@/components/routes/Editor/common/KBPreviewWrapper';
import { SgDocPreview } from '@/components/routes/Editor/SgDocs/SgDocPreview/SgDocPreview';
import { SgDocsComparator } from '@/components/routes/Editor/SgDocs/SgDocsComparator';
import { presentationApi } from '@/store/presentation.api';
import { store } from '@/store/store';

export function getRoutes(): RouteObject[] {
  return [
    {
      path: '/',
      element: <Layout />,
      loader: parametersLoader,
      children: [
        {
          index: true,
          element: <LandingPage />,
        },
        // Description: takes the user to a page to open a document with edit rights (as opposed to the one below)
        {
          path: 'review',
          element:  <CommonWrapper origin="xone"><ReviewPage /></CommonWrapper>
        },
        // Description: takes the user to a page to open a document but with no edit rights (as opposed to the one above)
        {
          path: 'preview',
          element:  <CommonWrapper origin="xone"><PreviewPage /></CommonWrapper>,
        },
        // Description: review page for users coming from X-One, allows users to edit a document
        //   Params:
        //     :docType: document type (i.e. Confirmation, TermSheet...)
        //     :assetClass: asset class (i.e. IRD, ENU...)
        //     :tradeRef: trade reference (i.e. ENU-1234567)
        //     :docId: document id (i.e. 0123456789)
        {
          path: 'xone/:docType/:assetClass/:docId/:tradeRef',
          element: <CommonWrapper origin="xone"><ReviewPage /></CommonWrapper>,
        },
        // Description: preview page for users coming from X-One, does not allow users to edit a document
        //   Params:
        //     :docType: document type (i.e. Confirmation, TermSheet...)
        //     :tradeRef: trade reference (i.e. ENU-1234567)
        //     :docId: document id (i.e. 0123456789)
        {
          path: 'Preview/xone/:docType/:tradeRef/:docId',
          element: <CommonWrapper origin="xone"><PreviewPage /></CommonWrapper>,
        },
        // Description: review page for users coming from Enterprise, allows users to edit a document
        //   Params:
        //     :docType: document type (i.e. Confirmation, TermSheet...)
        //     :assetClass: asset class (i.e. IRD, ENU...)
        //     :tradeRef: trade reference (i.e. ENU-1234567)
        //     :docId: document id (i.e. 0123456789)
        {
          path: 'ent/:docType/:assetClass/:docId/:tradeRef',
          element: <CommonWrapper origin="xone"><ReviewPage /></CommonWrapper>,
        },
        // Description: review page for users coming from Octane, allows users to edit a document
        //   Params:
        //     :codent: enterprise code (i.e. SG)
        //     :codtrs: document id (i.e. 0123456789)
        //     :chasing: chasing type (i.e. Reminder, Out_of_Target...)
        //     :chasingDate: chasing date (i.e. 21_02_24_12_00_00_AM)
        //     :client: client to be chased (i.e. TOTAL)
        {
          path: 'octane/CHASING/:codent/:codtrs/:chasing/:chasingDate/:client',
          element: <CommonWrapper origin="octane"><ReviewPage /></CommonWrapper>,
        },
        // Description: review page for users coming from Octane, allows users to edit a document
        //   Params:
        //     :docType: document type (i.e. Confirmation, TermSheet...)
        //     :assetClass: asset class (i.e. IRD, ENU...)
        //     :docId: document id (i.e. 0123456789)
        //     :tradeRef: trade reference (i.e. ENU-1234567)
        {
          path: 'octane/:docType/:assetClass/:docId/:tradeRef',
          element: <CommonWrapper origin="octane"><ReviewPage /></CommonWrapper>,
        },
        // Description: preview page for documents from SG Docs
        //   Params:
        //     :sgDocsId: document id (i.e. 0123456789)
        {
          path: 'Preview/xone/Sgdoc/:sgDocsId',
          element: <CommonWrapper origin="xone" isReadOnlyMode={true}><SgDocPreview /></CommonWrapper>
        },
        // Description: dedicated page to compare two SG Docs documents
        //   Params:
        //     :sgDocsId: document id (i.e. 0123456789)*/}
        //     :tradeRef: trade reference (i.e. ENU-1234567)*/}
        //     :docType: document type (i.e. Confirmation, TermSheet...)
        {
          path: 'showDiff/:sgDocsId/:tradeRef',
          element: <SgDocsComparator />,
        },
        // Description: dedicated page for KB users to edit a document
        //   Params:
        //     :docType: document type (i.e. Confirmation, TermSheet...)
        //     :tradeRef: trade reference (i.e. ENU-1234567)
        //     :docId: document id (i.e. 0123456789)
        {
          path: 'kb/:docType/:assetClass/:docId/:tradeRef',
          element: <CommonWrapper origin="kb"><ReviewPage /></CommonWrapper>,
        },
        // Description: dedicated page for KB users to preview a document
        //   Params:
        //     :docType: document type (i.e. Confirmation, TermSheet...)
        //     :tradeRef: trade reference (i.e. ENU-1234567)
        //     :docId: document id (i.e. 0123456789)
        {
          path: 'Preview/kb/:docType/:tradeRef/:docId',
          element: <KBPreviewWrapper origin="kb"><PreviewPage /></KBPreviewWrapper>,
        },
      ],
      errorElement: <PageNotFound />,
    },
  ];
}

async function parametersLoader({ request, params }: LoaderFunctionArgs)  {
  // GET FILE PARAMS
  const isChasing = isUrlChasingInfos(params);
  const fileParameters = getFileParams(params, isChasing);

  // GET QUERY PARAMS
  const queryParameters = getQueryParameters(request.url);

  // GET PERMISSIONS
  // const { permissions } = await PresentationService.getPermissions(new AbortController().signal);
  const { data } = await store.dispatch(presentationApi.endpoints.getPermissions.initiate());
  const permissions = data?.permissions ?? [];

  // GET RIGHTS
  const utilsPermissions = getUtilsPermissions(permissions);
  const mergedPermissions = getMergedPermssions(utilsPermissions);

  const rightsFromPayload = getRightsFromPayload(
    mergedPermissions,
    fileParameters.documentType ?? '',
    'CanWriteSmartDXDoc',
  );

  const rightsList = {
    canRead: rightsFromPayload[0],
    canWrite: queryParameters.noFlag ? !queryParameters.isreadonly : rightsFromPayload[1],
    canUseSmartDX: rightsFromPayload[2],
  };
  // sgwtWebAnalytics ???

  return { fileParameters, queryParameters, rightsList };
};

function getFileParams(params: Params<string>, isChasing: boolean): FileInfo | ChasingInfo {
  if (isChasing) {
    const { codent, codtrs, chasing, chasingDate, client } = params;

    return {
      documentType: 'CHASING',
      codent,
      codtrs: Number.parseInt(codtrs ?? '', 10),
      chasing,
      chasingDate,
      client: Number.parseInt(client ?? '', 10),
    };
  }

  const { docId, sgDocsId, docType: documentType, tradeRef: tradeReference, assetClass, isReadOnly } = params;
  const documentId = sgDocsId ?? docId;
  const isSGDoc = isDefined(sgDocsId);

  return {
    documentId,
    documentType: isSGDoc ? 'Confirmation' : documentType ?? '',
    tradeReference: tradeReference ?? '',
    assetClass: assetClass ?? '',
    isReadOnly: isSGDoc || isReadOnly === 'true',
  };
}

export function isChasingFileInfos(object: FileInfo | ChasingInfo): object is ChasingInfo {
  return 'codent' in object;
}

function getQueryParameters(requestUrl: string) {
  const searchParameters = new URL(requestUrl).searchParams;

  const { isredraft, isoriginal, isbomanager, SkipReviewCase, isreadonly } = {
    isredraft: searchParameters.get('isRedraft') ?? searchParameters.get('isredraft'),
    isoriginal: searchParameters.get('isOriginal') ?? searchParameters.get('isoriginal'),
    isbomanager: searchParameters.get('isBoManager') ?? searchParameters.get('isbomanager'),
    SkipReviewCase: searchParameters.get('SkipReviewCase') ?? searchParameters.get('skipreviewcase'),
    isreadonly: searchParameters.get('isreadonly'),
  };

  return {
    isredraft: isredraft === null ? false : isredraft !== 'False',
    isoriginal: isoriginal === null ? true : isoriginal === 'True',
    isbomanager: isbomanager === null ? false : isbomanager !== 'False',
    SkipReviewCase: SkipReviewCase === null ? true : SkipReviewCase === 'True',
    isreadonly: isreadonly === null ? false : isreadonly === 'True',
    noFlag: isredraft === null && isoriginal === null && isbomanager === null && SkipReviewCase === null,
  };
}
