import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Document, Page, pdfjs } from 'react-pdf';

// we added this config for react-pdf to read our pdf
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

export function PDFReader({ document }: { document: Blob | null; }) {
  const [pageNumber, setPageNumber] = useState<number>(0);

  const pagesArray = Array.from({ length: pageNumber }, (_value, index) => index + 1);

  const spinner = (
    <div className="text-center">
      <div className="spinner-border-md">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );

  const noData = (
    <div className="text-center">
      <p>
        <FormattedMessage id="reader.noPdf" />
      </p>
    </div>
  );

  return (
    <Document
      className="mx-auto pt-3 pb-5"
      file={document}
      loading={spinner}
      noData={noData}
      onLoadSuccess={({ numPages }) => setPageNumber(numPages)}
    >
      {pagesArray.map((pageNumber) => (
        <Page
          className="shadow-sm blvl1 w-25 px-4 mb-3 mx-auto"
          pageNumber={pageNumber}
          renderAnnotationLayer={false}
          renderMode={'canvas'}
          renderTextLayer={false}
          scale={1.5}
          width={600}
          key={pageNumber}
        />
      ))}
    </Document>
  );
}
