import { Conditional } from '@/components/common/Conditional';
import { ChaseModal } from '@/components/common/Modals/ChaseModal';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { modalOpenState, selectIsConsumed, selectTimedout } from '@/store/ui.slice';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

export function SendChasingButton({ draftXmlDocument }: { draftXmlDocument: string | null }) {
  const dispatch = useDispatch();

  const timedOut = useSelector(selectTimedout);
  const chasingConsumed = useSelector(selectIsConsumed('Chasing'));
  const cancelChasingConsumed = useSelector(selectIsConsumed('CancelChasing'));
  const isDisabled = timedOut || chasingConsumed || cancelChasingConsumed;

  const isUnderXlScreenSize = useMediaQuery([['(min-width: 1200px)', false]], true);
  const extraClasses = useMemo(() => (isUnderXlScreenSize ? '' : ' btn-text-icon'), [isUnderXlScreenSize]);

  async function handleClick() {
    dispatch(
      modalOpenState({
        modalId: 'Chasing',
        openState: true,
      }),
    );
  }

  return (
    <>
      <ChaseModal draftXmlDocument={draftXmlDocument} />

      <button
        className={`btn btn-info btn-lg${extraClasses}`}
        type="button"
        onClick={handleClick}
        disabled={isDisabled}
      >
        <Conditional condition={!isUnderXlScreenSize}>
          <FormattedMessage id={'header.button.chase'} />
        </Conditional>
        <i className="icon">send</i>
      </button>
    </>
  );
}
