import { Conditional } from '@/components/common/Conditional';
import { UserContext } from '@/context/UserContext';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { useLazySendChasingQuery } from '@/store/document.api';
import { selectChasing, selectChasingAttachment } from '@/store/document.slice';
import { selectEditor } from '@/store/editor.slice';
import { isPlainChasingInfos } from '@/types/fileInfos';
import { useContext, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

type SendButtonProps = { draftXmlDocument: string | null };

export function SendChasingButton({ draftXmlDocument }: SendButtonProps) {
  const chasing = useSelector(selectChasing);
  const attachedFiles = useSelector(selectChasingAttachment);
  const editor = useSelector(selectEditor);

  const isUnderXlScreenSize = useMediaQuery([['(min-width: 1200px)', false]], true);
  const extraClasses = useMemo(() => (isUnderXlScreenSize ? '' : ' btn-text-icon'), [isUnderXlScreenSize]);

  const { sgConnect } = useContext(UserContext).globalContext;

  const [triggerSend, sendResponse] = useLazySendChasingQuery();

  const loading = sendResponse.isLoading || sendResponse.isFetching;

  const saveChasing = async () => {
    const editorDocument = await editor?.saveToString();
    const draftXmlDocumentToSend = editorDocument?.draftXML ?? draftXmlDocument;

    if (!draftXmlDocumentToSend || !isPlainChasingInfos(chasing)) {
      return;
    }

    triggerSend({
      ...chasing,
      attachedFiles,
      workingDocument: draftXmlDocumentToSend,
      actionComment: '',
      userProfile: '',
      userBdrInitials: sgConnect?.getIdTokenClaims()?.sub.split('@')[0],
    });
  };

  return (
    <>
      <button className={`btn btn-info btn-lg${extraClasses}`} type="button" onClick={saveChasing}>
        <Conditional condition={!isUnderXlScreenSize}>
          {loading ? (
            <FormattedMessage id="header.button.chasing.save_sending" />
          ) : (
            <FormattedMessage id="header.button.chasing.save_send" />
          )}
        </Conditional>
        <i className="icon">send</i>
      </button>
    </>
  );
}
