import { modalOpenState } from '@/store/ui.slice';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { DropdownItem } from 'reactstrap';

export function AttachmentDropdownItem({ disabled }: { disabled: boolean }) {
  const dispatch = useDispatch();

  const openAttachementModal = () => dispatch(modalOpenState({ modalId: 'Attachment', openState: true }));

  return (
    <>
      <DropdownItem
        className="btn btn-flat-secondary btn-lg btn-icon-text"
        onClick={openAttachementModal}
        disabled={disabled}
      >
        <i className="icon">attach_file</i>
        <span>
          <FormattedMessage id="header.button.attachments" />
        </span>
      </DropdownItem>
    </>
  );
}
