import { isIssuingRelatedDocument } from '@/components/common/Modals/SaveAndSendModal';
import {
  SaveAndSendModalButton,
  SaveAndStoreButton,
  SaveButton,
} from '@/components/routes/Editor/common/SaveButtonsWrapper/SaveButtons/';
import { SaveAndSendButton } from '@/components/routes/Editor/common/SaveButtonsWrapper/SaveButtons/SaveAndSendButton';
import { useDocTypeObj } from '@/hooks/useDocType';
import { selectDocument } from '@/store/document.slice';
import { selectQueryParams } from '@/store/ui.slice';
import React from 'react';
import { useSelector } from 'react-redux';

export function SaveButtonsWrapper({ draftXmlDocument }: { draftXmlDocument: string | null }) {
  const { isredraft } = useSelector(selectQueryParams);
  const { documentType } = useSelector(selectDocument);
  const isIssuing = isIssuingRelatedDocument(documentType);
  const {
    Confirmation,
    PreConfirmation,
    Affirmation,
    PreMatching,
    FlowAdvice,
    FlowCorrespondence,
    TermSheet,
    FinalTerms,
  } = useDocTypeObj(documentType);

  const shouldDisplaySaveButton = Confirmation || PreMatching || FlowAdvice || isIssuing;
  const shouldDisplaySaveAndStoreButton = (Confirmation && isredraft) || isIssuing;
  const shouldDisplaySaveAndSendButton =
    (Confirmation && isredraft) ||
    Affirmation ||
    PreMatching ||
    FlowAdvice ||
    TermSheet ||
    FinalTerms ||
    FlowCorrespondence ||
    isIssuing;

  return (
    <>
      {shouldDisplaySaveButton && <SaveButton draftXmlDocument={draftXmlDocument} />}
      {shouldDisplaySaveAndStoreButton && <SaveAndStoreButton draftXmlDocument={draftXmlDocument} />}
      {shouldDisplaySaveAndSendButton && <SaveAndSendModalButton draftXmlDocument={draftXmlDocument} />}
      {PreConfirmation && <SaveAndSendButton draftXmlDocument={draftXmlDocument} />}
    </>
  );
}
