import { Conditional } from '@/components/common/Conditional';
import { CancelChaseModal } from '@/components/common/Modals/CancelChaseModal';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { modalOpenState, selectIsConsumed, selectTimedout } from '@/store/ui.slice';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

export function CancelChasingButton({ draftXmlDocument }: { draftXmlDocument: string | null }) {
  const dispatch = useDispatch();

  const timedOut = useSelector(selectTimedout);
  const chasingConsumed = useSelector(selectIsConsumed('Chasing'));
  const cancelChasingConsumed = useSelector(selectIsConsumed('CancelChasing'));
  const isDisabled = timedOut || chasingConsumed || cancelChasingConsumed;

  const isUnderXlScreenSize = useMediaQuery([['(min-width: 1200px)', false]], true);
  const extraClasses = useMemo(() => (isUnderXlScreenSize ? ' mr-1' : ' btn-text-icon mr-2'), [isUnderXlScreenSize]);

  async function handleClick() {
    dispatch(
      modalOpenState({
        modalId: 'CancelChasing',
        openState: true,
      }),
    );
  }

  return (
    <>
      <CancelChaseModal draftXmlDocument={draftXmlDocument} />

      <button
        className={`btn sgbs-btn-default btn-lg${extraClasses}`}
        type="button"
        onClick={handleClick}
        disabled={isDisabled}
      >
        <Conditional condition={!isUnderXlScreenSize}>
          <FormattedMessage id={'header.button.cancelchasing'} />
        </Conditional>
        <i className="icon">block</i>
      </button>
    </>
  );
}
