import { type FileInfo, setChasing, setFile } from '@/store/document.slice';
import type { ChasingInfo } from '@/types/fileInfos';
import { type Origin, type Rights, setOrigin, setQuery, setRights } from '@/store/ui.slice';
import type { PropsWithChildren } from 'react';
import { useDispatch } from 'react-redux';
import { useOutletContext } from 'react-router';
import { isChasingFileInfos } from '@/components/routes/routes';
import { setCurrentConfig } from '@/store/editor.slice';

type QueryParameters = {
  isredraft: boolean;
  isoriginal: boolean;
  isbomanager: boolean;
  SkipReviewCase: boolean;
  isreadonly: boolean;
  noFlag: boolean;
};

type LoaderData = {
  fileParameters: FileInfo | ChasingInfo;
  queryParameters: QueryParameters;
  rightsList: Rights;
};

export function KBPreviewWrapper({ origin, children }: PropsWithChildren<{origin: Origin}>) {
  const dispatch = useDispatch();

  dispatch(setOrigin(origin));

  // TODO ABO, refine loader data type
  const { fileParameters, queryParameters, rightsList } = useOutletContext() as LoaderData;

  const { canWrite } = rightsList;
  if(canWrite) {
    dispatch(setCurrentConfig({ mode: 'readOnly' }));
  }

  if (isChasingFileInfos(fileParameters)) {
    dispatch(setChasing(fileParameters));
  } else {
    dispatch(setFile(fileParameters));
  }

  dispatch(setQuery(queryParameters));
  dispatch(setRights({...rightsList, canWrite: false}));

  return children;
}