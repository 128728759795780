import { ConcurencyModal } from '@/components/common/Modals/ConcurencyModal';
import {
  SgDocPreviewEditorHeader,
} from '@/components/routes/Editor/SgDocs/SgDocPreview/SgDocPreviewEditor/SgDocPreviewEditorHeader';
import { useLoadSmartDxEditor } from '@/components/routes/Editor/SgDocs/useLoadSmartDxEditor';
import { selectEditor, setCurrentSgDocContent } from '@/store/editor.slice';
import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Editor } from '@/components/layout/Editor';
import { DocumentContext } from '@/context/DocumentContext';

export function SgDocPreviewEditor() {
  const dispatch = useDispatch();
  const editor = useSelector(selectEditor);
  const { document } = useContext(DocumentContext)

  // first boolean passed is reading right set up to true
  // second boolean passed is writing right set up to false
  // third boolean passed is use Smart DX right set up to true
  useLoadSmartDxEditor([true, false, true]);

  useEffect(() => {
    if (document && editor) {
      dispatch(setCurrentSgDocContent(document));
    }
  }, [dispatch, document, editor]);

  return (
    <Editor isLoading={!editor}>
      <ConcurencyModal />
      <SgDocPreviewEditorHeader />
    </Editor>
  );
}
