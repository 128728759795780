import { ConcurencyModal } from '@/components/common/Modals/ConcurencyModal';
import { DocumentContext } from '@/context/DocumentContext';
import { UserContext } from '@/context/UserContext';
import { useFetchDocumentBlob } from '@/hooks/useFetchDocumentBlob';
import { useContext } from 'react';
import { PreviewHeader } from './PreviewHeader';
import { Reader } from '@/components/layout/Reader';

export function SgDocPreviewReader() {
  const { document: documentAsXML } = useContext(DocumentContext);
  const { globalContext } = useContext(UserContext);

  const { documentBlob, isLoading } = useFetchDocumentBlob(documentAsXML, globalContext);

  const isHtmlBlob = documentBlob?.type === 'text/html';

  return (
    <Reader isLoading={isLoading} isHtmlBlob={isHtmlBlob} documentBlob={documentBlob}>
      <ConcurencyModal />
      <PreviewHeader document={documentBlob} />
    </Reader>
);
}
