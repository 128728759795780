import { ToasterContext } from '@/context/ToasterContext';
import { logger } from '@/helpers/logger';
import { selectDocument } from '@/store/document.slice';
import { selectEditor } from '@/store/editor.slice';
import type { SmartEditor } from '@/types/SmartCommsApi';
import { useCallback, useContext } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { download } from '../exportFile';
import { ExportItem } from './ExportItem';

type ExportHtmlItemProps = {
  enabled: boolean;
};

export function ExportHtmlItem({ enabled }: ExportHtmlItemProps) {
  const file = useSelector(selectDocument);
  const editor = useSelector(selectEditor);
  const pushToast = useContext(ToasterContext).pushToastMessage;
  const intl = useIntl();

  const exportHtml = useCallback(
    async (editor: SmartEditor | undefined) => {
      const currentContent = (await editor?.saveToString())?.draftXML;

      if (!editor) {
        pushToast({
          text: intl.formatMessage({ id: 'notification.export.failure' }),
          level: 'danger',
          icon: 'error_outline',
          type: 'alert',
        });
        return;
      }

      try {
        if (!currentContent) {
          logger.logWarning(
            'Failed to export document, draftXML is empty {tradeRef} {docId}',
            file?.tradeReference,
            file?.documentId,
          );
          return;
        }

        const fileBlobD = new Blob([currentContent], { type: 'text/xml' });
        download(fileBlobD, `${file?.documentType}_${file?.tradeReference}.html`);

        pushToast({
          text: intl.formatMessage({ id: 'notification.export.success' }),
          level: 'info',
          icon: 'info_outline',
        });
      } catch (e) {
        pushToast({
          text: intl.formatMessage({ id: 'notification.export.failure' }),
          level: 'danger',
          icon: 'error_outline',
          type: 'alert',
        });
      }
    },
    [file?.documentId, file?.documentType, file?.tradeReference, intl, pushToast],
  );

  return <ExportItem type="html" onClick={() => exportHtml(editor)} isDisabled={!enabled} />;
}
