import { ActionsDropdown } from '@/components/routes/Editor/common/ActionsWrapper/ActionsDropdown';
import { AttachmentButton } from '@/components/routes/Editor/common/ActionsWrapper/AttachmentButton';
import { CompareButton } from '@/components/routes/Editor/common/ActionsWrapper/CompareButton';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { selectDocument } from '@/store/document.slice';
import React from 'react';
import { useSelector } from 'react-redux';
import { isFileLoaded } from '../isFileLoaded';

type buttonsProps = {
  actions: string[];
};

export function ActionsWrapper({ actions }: buttonsProps) {
  const isUnderLgSize = useMediaQuery([['(min-width: 992px)', false]], true);
  const fileInfos = useSelector(selectDocument);

  const shouldDisplayCompare = actions.includes('compare');
  const shouldDisplayAttachment = actions.includes('attachment');

  return (
    <>
      {!isUnderLgSize ? (
        <>
          {shouldDisplayCompare && <CompareButton disabled={!isFileLoaded(fileInfos)} />}
          {shouldDisplayAttachment && <AttachmentButton disabled={!isFileLoaded(fileInfos)} />}
        </>
      ) : (
        <ActionsDropdown actions={actions} />
      )}
    </>
  );
}
