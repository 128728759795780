import { useEffect, useState } from 'react';
import { useLazyGetDocumentQuery } from '@/store/document.api';
import { useDispatch, useSelector } from 'react-redux';
import { selectQueryParams } from '@/store/ui.slice';
import { selectDocument, setFile } from '@/store/document.slice';
import { base64ToBytes } from '@/components/routes/Editor/utils';

export function useFetchDocument(isXone: boolean ): [ string | null, boolean] {
  const dispatch = useDispatch();

  const [document, setDocument] = useState<string | null>(null);
  const [isFileLoaded, setIsFileLoaded] = useState<boolean>(false);

  const fileInfos = useSelector(selectDocument);
  const { isoriginal } = useSelector(selectQueryParams);

  const [ trigger, { data: documentInfos, isFetching, isLoading, isSuccess } ] = useLazyGetDocumentQuery();

  useEffect(() => {
    const isDocumentQueryWorking = !isFileLoaded && !isFetching && !isLoading;
    const hasDocumentInfos = fileInfos.documentType !== '' && fileInfos.tradeReference !== '';

    if(isDocumentQueryWorking && hasDocumentInfos) {
      trigger({ ...fileInfos, isXone: isXone });

      if(isSuccess && documentInfos) {
        const {originalContent, workingContent, value, isReadOnly} = documentInfos;

        const selectedContent = isoriginal
          ? (originalContent ?? workingContent ?? null)
          : (workingContent ?? originalContent ?? null);

        const documentEncoded = getDocumentContent(fileInfos.documentId, selectedContent, value, isXone);
        const documentDecoded = documentEncoded !== null ? new TextDecoder().decode(base64ToBytes(documentEncoded)) : null;

        setDocument(documentDecoded);
        setIsFileLoaded(true);

        const { documentId } = fileInfos;
        if(documentId !== 'null' && selectedContent) {
          dispatch(
            setFile({
              ...fileInfos,
              isReadOnly,
            }),
          );
        }
      }
    }

    }, [ documentInfos, fileInfos, isFetching, isFileLoaded, isLoading, isSuccess, isoriginal, trigger, dispatch, isXone ])

  return [document, isFileLoaded];
}

function getDocumentContent(documentId: string | undefined, selectedContent: string | null, value: string | undefined, isXone: boolean) {
  if((documentId !== 'null' || !isXone) && selectedContent) {
    return selectedContent;
  }

  if(documentId === 'null' && value) {
    return value;
  }

  return null;
}